import { CONTENTFUL_STATUS } from './contentful-status'
const { createClient } = require('contentful')
const SPACE_ID = process.env.REACT_APP_SPACE_ID
export async function getEntryData({ ids, token, space, environment, includeStatus = true }) {
  const client = createClient({
    accessToken: 'yU3l1d9ZTnu1lr9QrjDlEb32i3DUuAP6LxNmCtu-_Kw',
    environment,
    space: SPACE_ID,
    host: 'preview.contentful.com',
  })
  const entries = await client.getEntries({
    include: 1,
    'sys.id[in]': ids.join(','),
  })
  if (!includeStatus) {
    return entries.items
  }
  return setEntryStatus({
    items: entries.items,
    token,
    space,
    environment,
  })
}

async function setEntryStatus({ items, token, space, environment }) {
  const cda_client = createClient({
    space: SPACE_ID,
    environment,
    accessToken: token,
  })
  const previewIds = items.map(item => item.sys.id).join(',')
  const response = await cda_client.getEntries({ 'sys.id[in]': previewIds })
  let cda_entries = response.items
  items.map(previewEntry => {
    previewEntry.sys.status = CONTENTFUL_STATUS.UNKNOWN
    let deliveryEntry = cda_entries.find(i => i.sys.id === previewEntry.sys.id)
    if (deliveryEntry) {
      if (deliveryEntry.sys.updatedAt === previewEntry.sys.updatedAt) {
        previewEntry.sys.status = CONTENTFUL_STATUS.PUBLISHED
      } else if (deliveryEntry.sys.updatedAt !== previewEntry.sys.updatedAt) {
        previewEntry.sys.status = CONTENTFUL_STATUS.UPDATED
      }
    } else {
      previewEntry.sys.status = CONTENTFUL_STATUS.DRAFT
    }
    return previewEntry
  })
  return items
}
