import styled from 'styled-components'
import { Row, ColHeader } from '../styled'

export const TagListContainer = styled.div`
  max-width: 900px;
  ${Row} {
    ${ColHeader} {
      padding: 5px;
      height: 35px;
    }
  }
`

export const InputWrap = styled.div`
  padding: 5px;
  > input {
    height: 25px;
  }
`

export const PreviewList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    padding: 4px;
    min-height: 100px;
    width: 25%;
    max-width: 25%;
    > h3 {
      font-size: 14px;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
`

export const PreviewText = styled.div`
  color: gray;
  display: block;
  width: 100%;
  padding: 8px;
`
