import React, { useEffect, useState } from 'react'
import { HelpText, Switch } from '@contentful/forma-36-react-components'
import useContentfulExtension from '../hooks/useContentfulExtension'

export default () => {
  const extension = useContentfulExtension()
  const [value, setValue] = useState(undefined)
  const [helpText, setHelpText] = useState()

  useEffect(() => {
    if (extension) {
      const v = extension.field.getValue()
      setValue(v === undefined ? extension.parameters.instance.defaultValue : v)
      setHelpText(extension.parameters.instance.defaultValue)
      extension.window.startAutoResizer()
    }
    return () => {
      if (extension) {
        extension.window.stopAutoResizer()
      }
    }
  }, [extension])

  useEffect(() => {
    if (extension && value !== undefined) {
      extension.field.setValue(value)
    }
  }, [extension, value])

  if (value === undefined || !extension) {
    return null
  }

  return (
    <div>
      <div style={{ margin: '9px 0' }}>
        <Switch
          isChecked={value}
          onToggle={isChecked => {
            setValue(isChecked)
          }}
        />
      </div>
      {helpText && (
        <div style={{ margin: '9px 0' }}>
          <HelpText>{helpText}</HelpText>
        </div>
      )}
    </div>
  )
}
