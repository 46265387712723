import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Spinner, HelpText, Tooltip, Icon, Note, Switch } from '@contentful/forma-36-react-components'
import styled from 'styled-components'
import { Flex } from 'rebass'
import useContentfulExtension from '../hooks/useContentfulExtension'
import _get from 'lodash.get'
import debounce from 'lodash.debounce'
import _isEqual from 'lodash.isequal'
import {
  getName,
  getDescription,
  getAdCategory,
  getMain16x9Annotated,
  getMain16x7,
  getCategoryTags,
  setText,
  setImage,
  setAdCategory,
  setCategoryTags,
} from './contentfulHelper'

const LoaderBackground = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`

export default () => {
  const extension = useContentfulExtension()
  const apikey = useRef(undefined)
  const [selectedFields, setSelectedFields] = useState(undefined)
  const [nid, setNid] = useState(undefined)
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [cometData, setCometData] = useState(undefined)
  const [error, setError] = useState(undefined)

  const fetchComet = useCallback(async id => {
    try {
      setIsLoading(true)
      const response = await fetch(`https://internal.tv4.incomet.io/metadata/programs?nid=${id}&apikey=${apikey.current}`)
      const result = await response.json()
      const data =
        result.data.length > 0
          ? {
              name: getName(result.data[0]),
              description: getDescription(result.data[0]),
              adCategory: getAdCategory(result.data[0]),
              main16x9Annotated: getMain16x9Annotated(result.data[0]),
              main16x7: getMain16x7(result.data[0]),
              categoryTags: getCategoryTags(result.data[0]),
            }
          : undefined

      setCometData(data)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  })
  const debouncedFetchComet = useCallback(debounce(fetchComet, 500), [])

  useEffect(() => {
    if (!extension) {
      return
    }
    apikey.current = _get(extension, 'parameters.installation.apikey')
    setOptions((_get(extension, 'field.items.validations') || []).find(v => !!v.in).in || [])
    setSelectedFields(extension.field.getValue() || [])
    setNid(extension.entry.fields.nid.getValue())
    const detachNidListener = extension.entry.fields.nid.onValueChanged(id => {
      setNid(id)
    })

    const detachFieldListener = extension.field.onValueChanged(value => {
      setSelectedFields(prevSelectedFields => {
        if (!_isEqual((prevSelectedFields || []).sort(), (value || []).sort())) {
          return value || []
        }
        return prevSelectedFields || []
      })
    })
    extension.window.startAutoResizer()
    return () => {
      if (extension) {
        extension.window.stopAutoResizer()
        detachFieldListener()
        detachNidListener()
      }
    }
  }, [extension])

  useEffect(() => {
    if (nid) {
      debouncedFetchComet(nid)
    }
  }, [nid, debouncedFetchComet])

  useEffect(() => {
    const populateFields = async () => {
      setIsLoading(true)
      try {
        // Apply sequentially for less likeliness to be rate limited
        selectedFields.includes('name') && (await setText(extension, 'name', cometData.name))
        selectedFields.includes('description') && (await setText(extension, 'description', cometData.description))
        selectedFields.includes('adCategory') && (await setAdCategory(extension, cometData.adCategory))
        selectedFields.includes('main16x9Annotated') &&
          (await setImage(extension, 'main16x9Annotated', cometData.main16x9Annotated))
        selectedFields.includes('main16x7') && (await setImage(extension, 'main16x7', cometData.main16x7))
        selectedFields.includes('categoryTags') && (await setCategoryTags(extension, cometData.categoryTags))
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (cometData) {
      populateFields()
    }
  }, [extension, selectedFields, cometData])

  useEffect(() => {
    if (extension && selectedFields) {
      if (!_isEqual((extension.field.getValue() || []).sort(), selectedFields.sort())) {
        extension.field.setValue(selectedFields)
      }
    }
  }, [extension, selectedFields])

  if (!extension || options.length === 0) {
    return null
  }

  if (!nid || (!isLoading && !cometData)) {
    return (
      <Note>
        There is no Program with nid <strong>"{nid}"</strong> found in Comet.
      </Note>
    )
  }

  if (!isLoading && error) {
    return <Note noteType="negative">{_get(error, 'message') || 'Something went wrong'}</Note>
  }

  const hasValue = field => {
    const value = _get(cometData, field)
    return Array.isArray(value) ? value.length > 0 : !!value
  }

  return (
    <div>
      <Flex width={1} flexDirection="row" flexWrap="wrap">
        <Flex width={1 / 3} flexDirection="row">
          <Switch
            id="allfields"
            name="allfields"
            labelText="All fields"
            isChecked={
              _isEqual(options.filter(hasValue).sort(), (selectedFields || []).sort()) &&
              options.filter(hasValue).length > 0
            }
            isDisabled={isLoading || options.filter(hasValue).length === 0}
            onToggle={isChecked => {
              if (isChecked) {
                setSelectedFields(options.filter(hasValue))
              } else {
                setSelectedFields([])
              }
            }}
          />
        </Flex>
        {options.map(option => {
          const label = extension.contentType.fields.find(f => f.id === option).name
          return (
            <Flex width={1 / 3} key={option} flexDirection="row">
              <Tooltip content={hasValue(option) ? `Found in Comet` : `No "${label}" found in Comet`} place="bottom">
                <Flex flexDirection="row">
                  <Switch
                    id={option}
                    name={option}
                    isDisabled={isLoading || !hasValue(option)}
                    labelText={label}
                    isChecked={selectedFields.includes(option)}
                    onToggle={isChecked => {
                      if (isChecked) {
                        setSelectedFields([...selectedFields, option])
                      } else {
                        setSelectedFields(selectedFields.filter(v => v !== option))
                      }
                    }}
                  />

                  {cometData && (
                    <Icon
                      icon={hasValue(option) ? 'CheckCircle' : 'ErrorCircle'}
                      size="tiny"
                      color={hasValue(option) ? 'positive' : 'warning'}
                      style={{ marginLeft: 2, verticalAlign: 'middle' }}
                    />
                  )}
                </Flex>
              </Tooltip>
            </Flex>
          )
        })}
      </Flex>
      <div style={{ position: 'relative', minHeight: '47px' }}>
        {isLoading && (
          <LoaderBackground>
            <Spinner size="large" />
          </LoaderBackground>
        )}
        {selectedFields.length > 0 ? (
          <Note noteType="warning" style={{ position: 'relative' }}>
            Local edits on the selected fields will be lost.
          </Note>
        ) : (
          <HelpText style={{ padding: '12px 0' }}>Optionally connect any of the available fields with Comet.</HelpText>
        )}
      </div>
    </div>
  )
}
