import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL

export const getClient = graphqlUrl => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          )
        }
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({
        uri: graphqlUrl,
        credentials: 'same-origin',
        headers: {
          'client-name': 'tv4-contentful-ui-extensions',
          'client-version': '1.0.0',
        },
      }),
    ]),
    cache: new InMemoryCache(),
  })
}

const client = getClient(GRAPHQL_URL)

export default client
