import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    const errorComponent = this.props
    if (this.state.hasError) {
      if (errorComponent) {
        return <> {errorComponent}</>
      }
      return null
      // You can render any custom fallback UI
    }
    return this.props.children
  }
}
