import { createClient } from 'contentful'

const SPACE_ID = process.env.REACT_APP_SPACE_ID
const HOST = 'https://api.contentful.com'
const DEFAULT_LOCALE = 'en-US'

export function getEntries({ sysId, fieldId, accessToken, environment }) {
  return new Promise((resolve, reject) => {
    const cda = createClient({
      space: SPACE_ID,
      accessToken: accessToken,
      environment: environment,
      host: HOST,
      locale: DEFAULT_LOCALE,
    })
    cda.getEntries({ include: 10, 'sys.id': sysId }).then(entries => {
      if (entries.total > 0 && entries.items.length > 0) {
        resolve(entries.items[0].fields[fieldId])
      }
    })
  })
}

export function getEntriesByIds({ ids, accessToken, environment }) {
  return new Promise((resolve, reject) => {
    const cda = createClient({
      space: SPACE_ID,
      accessToken: accessToken,
      host: HOST,
      environment,
    })

    cda
      .getEntries({
        'sys.id[in]': ids.join(','),
        locale: DEFAULT_LOCALE,
      })
      .then(entries => {
        resolve(entries.items)
      })
  })
}
