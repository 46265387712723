import React, { useEffect, useState, useRef } from 'react'
import { HelpText, Switch, Note, SelectField, Option } from '@contentful/forma-36-react-components'
import VideoAssetFinder from '../VideoAssetFinder'
import useContentfulExtension from '../hooks/useContentfulExtension'

const SuggestedEpisodeEditor = props => {
  const videoAssetFinder = useRef(null)
  const extension = useContentfulExtension()
  const [config, setConfig] = useState(undefined)
  const [nid, setNid] = useState(undefined)

  useEffect(() => {
    if (extension) {
      const values = extension.field.getValue()
      setConfig(
        values || {
          type: 'LATEST_EPISODE',
          keepWatching: false,
        }
      )

      setNid(extension.entry.fields.nid.getValue())
      extension.entry.fields.nid.onValueChanged(id => {
        setNid(id)
      })
      extension.window.startAutoResizer()
    }
    return () => {
      if (extension) {
        extension.window.stopAutoResizer()
      }
    }
  }, [extension])

  useEffect(() => {
    if (extension && config) {
      if (config.type === 'ASSET' && !config.asset) {
        extension.field.setInvalid(true)
      } else {
        extension.field.setInvalid(false)
        extension.field.setValue(config)
      }
    }
  }, [extension, config])

  if (!config || !extension) {
    return null
  }

  if (!nid) {
    return <Note>Need to set a valid "nid" before setting suggested episode</Note>
  }

  return (
    <div>
      <div style={{ paddingBottom: 10 }}>
        <SelectField
          labelText="Avsnittsregel"
          required
          value={config.type}
          onChange={e => {
            setConfig({
              ...config,
              asset: undefined,
              type: e.target.value,
            })
          }}
        >
          <Option value="LATEST_EPISODE">Senaste avsnittet</Option>
          <Option value="FIRST_EPISODE">Första avsnittet</Option>
          <Option value="FIRST_EPISODE_LATEST_SEASON">Första avsnittet senaste säsongen</Option>
          <Option value="ASSET">Specifikt avsnitt</Option>
        </SelectField>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Switch
          labelText="Keep watching"
          isChecked={config.keepWatching}
          onToggle={isChecked => {
            setConfig({
              ...config,
              keepWatching: isChecked,
            })
          }}
        />
        <HelpText>Låt en användares tittarhistorik påverka valt avsnitt</HelpText>
      </div>
      {config.type === 'ASSET' && (
        <div>
          <VideoAssetFinder
            ref={videoAssetFinder}
            selectedId={config.asset}
            parentExtension={extension}
            onVideoAssetSelected={videoAsset => {
              setConfig({
                ...config,
                asset: `${videoAsset.id}`,
              })
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SuggestedEpisodeEditor
