import React, { useEffect, useState } from 'react'
import useContentfulExtension from '../hooks/useContentfulExtension'
import { HelpText, RadioButtonField, FieldGroup, Form } from '@contentful/forma-36-react-components'
import _get from 'lodash.get'

export default () => {
  const extension = useContentfulExtension()
  const [value, setValue] = useState(undefined)
  const [options, setOptions] = useState([])
  const [helpText, setHelpText] = useState()

  useEffect(() => {
    if (extension) {
      const options = (_get(extension, 'field.validations') || []).find(v => !!v.in).in || []
      setOptions(options)
      setValue(extension.field.getValue() || extension.parameters.instance.defaultValue)
      setHelpText(extension.parameters.instance.helpText)
      extension.window.startAutoResizer()
    }
    return () => {
      if (extension) {
        extension.window.stopAutoResizer()
      }
    }
  }, [extension])

  useEffect(() => {
    if (extension && value) {
      extension.field.setValue(value)
    }
  }, [extension, value])

  if (value === undefined || !extension) {
    return null
  }

  return (
    <div style={{marginTop:"0.75rem"}}>
      <Form>
        <FieldGroup>
          {options.map(o => (
            <RadioButtonField id={extension.field.id+o} labelText={o} key={o} value={o} checked={value === o} name={extension.field.id} onChange={e => {
              setValue(e.target.value)
            }} />
          ))}
        </FieldGroup>
        {helpText && (
          <div style={{ margin: '9px 0' }}>
            <HelpText>{helpText}</HelpText>
          </div>
        )}
      </Form>
    </div>
  )
}
