import { HelpText } from '@contentful/forma-36-react-components'
import styled from 'styled-components'

export const TagListContainer = styled.div`
  max-width: 900px;
`

export const PreviewList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    padding: 4px;
    min-height: 100px;
    width: 25%;
    max-width: 25%;
    > h3 {
      font-size: 14px;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
`

export const PreviewItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const PreviewText = styled.div`
  color: gray;
  display: block;
  width: 100%;
  padding: 8px;
`

export const PreviewPublishedText = styled(HelpText)`
  margin-top: auto;
  font-size: 0.875em;
`
