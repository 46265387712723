import React, { Component } from 'react'
import { Flex } from 'rebass'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button } from '@contentful/forma-36-react-components'
import { getListStyle, getItemStyle, reorder } from '../../utils/multipleEntryUtils'
import { parsePreviewData, parseManagementData } from './parsePanelContentfulData'
import * as contentfulManagementClient from '../../modules/contentful-management-client'
import * as contentfulPreviewClient from '../../modules/contentful-preview-client'
import * as contentfulDeliveryClient from '../../modules/contentful-delivery-client' // only used in dev.
import Loader, { LoaderOverlay } from '../styled/Loader'
import _get from 'lodash.get'
import uuidv1 from 'uuid/v1'
import PanelEditor from './PanelEditorItem'
const REACT_APP_MANAGEMENT_TOKEN_DEV = process.env.REACT_APP_MANAGEMENT_TOKEN_DEV || 'N/A' // only used in dev mode.
const REACT_APP_DELIVERY_TOKEN_DEV = process.env.REACT_APP_DELIVERY_TOKEN_DEV || 'N/A' // only used in dev mode.
const REACT_APP_CONTENTFUL_ENV_DEV = process.env.REACT_APP_CONTENTFUL_ENV_DEV || 'N/A' // only used in dev mode.

const REFRESH_INTERVAL = 7500
let refreshInterval

export default class PanelsEditor extends Component {
  state = {
    entries: [],
    currentEntryId: null,
    extension: null,
    showCreateAndAdd: false,
    createdLink: null,
  }

  /**
   * Only run when initialized through contentful. For docs on extension capabilities check the UI Extension SDK
   */
  setStateFromExtension = extension => {
    const fieldValues = extension.field.getValue() || []
    this.setState(
      {
        extension: extension,
        token: _get(extension, 'parameters.installation.managementToken'),
        deliveryToken: _get(extension, 'parameters.installation.deliveryToken'),
        environment: extension.ids.environment,
        currentEntryId: extension.entry.getSys().id,
        entries: fieldValues.map(link => {
          return Object.assign({}, link, {
            loading: true,
            fields: {},
          })
        }),
      },
      () => {
        this.fetchPublishedStatus({
          environment: this.state.environment,
          deliveryToken: this.state.deliveryToken,
          entries: this.state.entries,
          token: this.state.token,
        })
        extension.window.startAutoResizer()
      }
    )
  }
  /**
   * Get field values & system metadata for linked fields status through the preview API.
   */
  fetchPublishedStatus = async ({ entries, token, deliveryToken, environment }) => {
    const ids = entries.map(e => e.sys.id)
    const previewData = await contentfulPreviewClient.getEntryData({
      ids,
      token: deliveryToken,
      environment,
    })
    const nextStateEntries = entries.map(entry => {
      const previewMetaData = previewData.find(md => md.sys.id === entry.sys.id)
      const originalEntry = entries.find(ed => ed.sys.id === entry.sys.id)
      const parsedEntry = parsePreviewData({ previewMetaData, originalEntry })
      return { ...parsedEntry, loading: false, expanded: false }
    })
    this.setState({
      entries: nextStateEntries,
    })
  }
  createRefreshInterval = () => {
    return setInterval(this.refresh, REFRESH_INTERVAL)
  }
  onExpandCard = index => {
    const nextStateEntries = [...this.state.entries]
    nextStateEntries[index].expanded = !nextStateEntries[index].expanded
    this.setState({
      entries: nextStateEntries,
    })
  }

  componentDidMount() {
    if (window.contentfulExtension) {
      window.contentfulExtension.init(extension => {
        this.setStateFromExtension(extension)
      })
    } else if (window.location.hostname === 'localhost') {
      this.setupDataForDev()
    }
    refreshInterval = this.createRefreshInterval()
  }

  componentWillUnmount() {
    clearInterval(refreshInterval)
  }

  getEntryLinks = entries => {
    return entries.map(entry => ({
      sys: { type: 'Link', linkType: 'Entry', id: `${entry.sys.id}` },
    }))
  }
  refresh = () => {
    if (this.state.dragging) {
      return
    }
    const { extension, environment, token, deliveryToken } = this.state
    const entries = extension
      ? (extension.field.getValue() || []).map(link => {
          return Object.assign({}, link, { loading: true, fields: {} }) // prod mode
        })
      : this.state.entries // dev mode
    this.fetchPublishedStatus({ entries, environment, token, deliveryToken })
  }

  onRemoveItem = sysId => {
    const remainingItems = this.state.entries.filter(entry => entry.sys.id !== sysId)
    this.setState({
      entries: remainingItems,
    })
    const entryLinks = this.getEntryLinks(remainingItems)
    if (this.state.extension) {
      this.state.extension.field.setValue(entryLinks)
    }
  }
  /**
   *
   */
  createEntry = ({ contentType, fields, accessToken, environment, deliveryToken }) => {
    const id = uuidv1()
    this.setState({
      resultsLoading: true,
    })
    contentfulManagementClient
      .createEntry({
        contentType,
        id,
        fields,
        shouldPublish: false,
        environment,
        token: accessToken,
      })
      .then(async createdResult => {
        const createdItems = await contentfulManagementClient.getEntryData({
          ids: [createdResult.sys.id],
          token: accessToken,
          environment,
        })
        const deliveryMetaData = await contentfulDeliveryClient.getEntriesByIds({
          ids: [createdResult.sys.id],
          accessToken: deliveryToken,
          environment,
        })
        const entryWithImageData = parseManagementData({
          managementMetaData: createdItems[0],
          deliveryMetaData: deliveryMetaData[0],
          createdResult,
        })
        const newEntries = this.state.entries.concat([entryWithImageData])
        this.setState({ entries: newEntries, resultsLoading: false })
        if (this.state.extension) {
          const entryLinks = this.getEntryLinks(newEntries)
          this.state.extension.field.setValue(entryLinks)
        }
      })
  }
  onRemoveItem = sysId => {
    const remainingItems = this.state.entries.filter(entry => entry.sys.id !== sysId)
    this.setState({
      entries: remainingItems,
    })
    const entryLinks = this.getEntryLinks(remainingItems)
    if (this.state.extension) {
      this.state.extension.field.setValue(entryLinks)
    }
  }
  onDragStart = () => {
    this.setState({
      dragging: true,
    })
    clearInterval(refreshInterval)
  }
  onDragEnd = result => {
    this.setState({
      dragging: false,
    })
    refreshInterval = this.createRefreshInterval()
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedEntries = reorder(this.state.entries, result.source.index, result.destination.index)
    if (this.state.extension) {
      this.state.extension.field.setValue(this.getEntryLinks(reorderedEntries))
    }

    this.setState({
      entries: reorderedEntries,
    })
  }
  linkExistingEntries = () => {
    if (this.state.extension) {
      this.state.extension.dialogs
        .selectSingleEntry({
          locale: 'en-US',
          contentTypes: this.getValidPanelTypes(),
        })
        .then(selectedEntry => {
          if (!selectedEntry || this.state.entries.find(e => e.sys.id === selectedEntry.sys.id)) {
            return
          }
          const nextStateEntries = [...this.state.entries, selectedEntry]
          this.setState({
            entries: nextStateEntries,
          })
          this.state.extension.field.setValue(this.getEntryLinks(nextStateEntries))
        })
    }
  }

  onNavigateToEntry = ({ e, entryId }) => {
    if (this.state.extension) {
      e.preventDefault()
      this.state.extension.navigator.openEntry(entryId, { slideIn: true })
    }
  }

  getValidPanelTypes = () => {
    const validContentTypes =
      _get(
        (_get(this.state.extension, 'field.items.validations') || []).find(v => v.linkContentType),
        'linkContentType'
      ) || []

    return validContentTypes
  }

  render() {
    const { entries, currentEntryId, resultsLoading, environment, token, deliveryToken } = this.state

    return (
      <div style={{ padding: '8px 0', position: 'relative' }}>
        {resultsLoading && <LoaderOverlay />}
        <div>
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {entries.map((panel, index) => (
                    <Draggable key={`${panel.sys.id}_${index}`} draggableId={`${panel.sys.id}_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {!panel || panel.loading ? (
                            <div>
                              <Loader style={{ margin: '0 10px 0 5px' }} />
                              Loading ...
                            </div>
                          ) : (
                            <PanelEditor
                              key={panel.sys.id}
                              panel={panel}
                              index={index}
                              style={{
                                maxHeight: snapshot.isDragging ? '44px' : 'inherit',
                              }}
                              refresh={this.refresh}
                              onExpandCard={this.onExpandCard}
                              onNavigateToEntry={this.onNavigateToEntry}
                              environment={environment}
                              entryId={currentEntryId}
                              onRemoveItem={this.onRemoveItem}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Flex flexWrap="wrap" width={1}>
          {this.getValidPanelTypes().map(panelType => (
            <Button
              style={{ margin: '4px' }}
              key={panelType}
              size="small"
              onClick={() => {
                this.createEntry({
                  contentType: panelType,
                  environment: environment || REACT_APP_CONTENTFUL_ENV_DEV,
                  accessToken: token || REACT_APP_MANAGEMENT_TOKEN_DEV,
                  deliveryToken: deliveryToken || REACT_APP_DELIVERY_TOKEN_DEV,
                })
              }}
            >
              Add {panelType}
            </Button>
          ))}
          <Button buttonType="positive" size="small" style={{ margin: '4px' }} onClick={this.linkExistingEntries}>
            Link existing entries
          </Button>
        </Flex>
      </div>
    )
  }
}
