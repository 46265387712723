import React, { Component } from 'react'
import debounce from '../debounce'
import styled from 'styled-components'
import { TextInput } from '@contentful/forma-36-react-components'

export const SearchLabel = styled.label`
  display: block;
  color: #757575;
`

export default class SearchBox extends Component {
  state = { query: this.props.query }
  componentWillMount() {
    this.handleSearchDebounced = debounce(() => {
      this.props.handleSearch.apply(this, [this.state.query])
    }, 1000)
  }

  onChange = e => {
    const query = e.target.value
    const shouldSearch = query.length > this.state.query.length && query.length >= 3

    this.setState({ query: query }, () => {
      if (shouldSearch) {
        this.handleSearchDebounced()
      }
    })
  }

  render() {
    return <TextInput type="search" value={this.state.query} onChange={this.onChange} width="medium" />
  }
}
