import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apolloClient from '../../graphql/apolloClient'
import graphql from 'graphql-tag'
import { Col } from '../styled'
import gql from 'graphql-tag'
import { imageProxyUrl } from '../../modules/image-proxy'
import { Thumbnail, ResultsGrid, ResultsWrapper, LoadMore, CloseButton, Title, Upcoming } from '../styled/ResultsGrid'
import { Flex } from '../styled/Flex'
import _get from 'lodash.get'
import SearchBox, { SearchLabel } from '../SearchBox'
import Loader from '../styled/Loader'
import NormalLayout from './NormalLayout'
import { isBefore } from 'date-fns'

const isUpcoming = broadcastDateTime => isBefore(new Date(), broadcastDateTime)

export default class VideoAssetFinder extends Component {
  state = {
    suggestions: [],
    totalHits: 0,
    selected: null,
    extension: null,
    query: '',
    offset: 0,
    noHits: false,
  }

  async componentDidMount() {
    const { parentExtension, selectedId, handleExpiredAsset } = this.props

    if (!parentExtension) {
      window.contentfulExtension &&
        window.contentfulExtension.init(extension => {
          this.setState({
            extension,
          })

          if (extension.field.getValue()) {
            this.search(extension.field.getValue()).then(s => {
              const { videoAsset } = s
              this.setState(
                {
                  selected: {
                    id: videoAsset.id,
                    image: videoAsset.image,
                    title: videoAsset.title,
                  },
                },
                () => {
                  this.closeResults()
                  this.state.extension.window.updateHeight(250)
                }
              )
            })
          }
        })
    } else {
      const initialValues = await apolloClient.query({
        query: gql(`
      query videoAssets($id: String!) {
      videoAsset(id: $id, includeUpcoming: true) {
        id
        title
        description
        image
        broadcastDateTime
      }
    }`),
        variables: { id: selectedId },
      })
      if (_get(initialValues, 'data.videoAsset')) {
        const { id, image, title, broadcastDateTime } = initialValues.data.videoAsset
        this.setState({ selected: { id, image, title, broadcastDateTime } })
      } else {
        if (!!handleExpiredAsset) {
          // Initial asset doesn't exist anymore, notify parent
          await handleExpiredAsset()
        }
      }
    }
  }

  search = async (q, offset = 0) => {
    return new Promise((resolve, reject) => {
      let newState = {
        query: q,
        offset: offset,
      }

      if (offset === 0) {
        newState = { ...newState, suggestions: [] }
      }

      this.setState(
        {
          ...newState,
          fetchingResults: true,
          noHits: false,
        },
        () => {
          return apolloClient
            .query({
              query: graphql(`
                query VideoAssetSearch($q: String, $limit: Int, $offset: Int) {
                  videoAsset(id: $q, includeUpcoming: true) {
                    id
                    title
                    description
                    image
                    broadcastDateTime
                  }
                  videoAssetSearch(q: $q, limit: $limit, offset: $offset, includeUpcoming: true) {
                    totalHits
                    videoAssets {
                      title
                      description
                      image
                      id
                      broadcastDateTime
                    }
                  }
                }
              `),
              variables: {
                q: this.state.query,
                limit: 10,
                offset: this.state.offset,
              },
            })
            .then(r => {
              if (r.data.videoAssetSearch.totalHits > 0) {
                this.setState({
                  suggestions: this.state.suggestions.concat(r.data.videoAssetSearch.videoAssets),
                  totalHits: r.data.videoAssetSearch.totalHits,
                  fetchingResults: false,
                })
                this.state.extension && this.state.extension.window.updateHeight(640)
              } else {
                this.setState({
                  noHits: true,
                  fetchingResults: false,
                })
              }

              resolve(r.data)
            })
            .catch(e => reject(e))
        }
      )
    })
  }
  renderResult = data => (
    <ResultsWrapper>
      <CloseButton onClick={this.closeResults}>Stäng</CloseButton>
      <ResultsGrid>
        <Flex>
          <div style={{ flexGrow: 1, padding: '3px', paddingLeft: '5px' }}>
            <strong>{this.state.totalHits}</strong> träffar
          </div>
        </Flex>
        {data.map((suggestion, i) => (
          <Col key={i} onClick={() => this.setSelected(suggestion)}>
            <Thumbnail src={imageProxyUrl(suggestion.image, 100)} />
            <Title>{suggestion.title}</Title>
            {isUpcoming(suggestion.broadcastDateTime) && <Upcoming>Kommande</Upcoming>}
          </Col>
        ))}
        {this.state.totalHits > 10 && this.state.totalHits > this.state.offset + 10 && (
          <LoadMore onClick={() => this.search(this.state.query, this.state.offset + 10)}>Ladda fler</LoadMore>
        )}
      </ResultsGrid>
    </ResultsWrapper>
  )
  closeResults = () => {
    this.setState({ suggestions: [] }, () => {
      this.state.extension && this.state.extension.window.updateHeight(250)
    })
    if (this.props.onCloseResults) {
      this.props.onCloseResults()
    }
  }
  setSelected = asset => {
    this.closeResults()
    if (this.props.onVideoAssetSelected) {
      this.props.onVideoAssetSelected(asset)
      this.setState({ selected: asset })
    } else {
      this.setState({ selected: asset }, () => {
        if (this.state.extension) {
          const value = this.state.selected ? this.state.selected.id + '' : null
          this.state.extension.field.setValue(value).catch(error => {
            console.log('Error: ' + JSON.stringify(error))
          })
        }
      })
    }
  }
  render() {
    const { smallLayout } = this.props
    const { suggestions, selected, noHits, fetchingResults, query } = this.state
    return (
      <>
        {smallLayout ? (
          <div> {selected && selected.id} </div>
        ) : (
          <NormalLayout
            renderResult={this.renderResult}
            suggestions={suggestions}
            selected={selected}
            noHits={noHits}
            reset={() => this.setSelected(null)}
            fetchingResults={fetchingResults}
            isUpcoming={selected && isUpcoming(selected.broadcastDateTime)}
          />
        )}

        <>
          <SearchLabel>Sökterm:</SearchLabel>
          <SearchBox query={query} handleSearch={this.search} />
          {fetchingResults && <Loader />}
          {suggestions && suggestions.length > 0 && this.renderResult(suggestions)}
          {noHits && <p>Inga träffar</p>}
        </>
      </>
    )
  }
}

VideoAssetFinder.propTypes = {
  parentExtension: PropTypes.any,
  onVideoAssetSelected: PropTypes.func,
}
