import React, { Component } from 'react'
import apolloClient from '../../graphql/apolloClient'
import graphql from 'graphql-tag'
import debounce from '../../debounce'
import Preview from './taglist-preview'
import { InputWrap, TagListContainer } from './style'
import { RadioGroup, RadioLabel, Flex, Row, ColHeader } from '../styled'

const ASSETTYPES = {
  CLIP: 'CLIP',
  EPISODE: 'EPISODE',
  VIRTUAL_CHANNEL: 'VIRTUAL_CHANNEL',
  ANY: null,
}
export default class TagList extends Component {
  state = {
    loadingPreview: false,
    assetType: ASSETTYPES.ANY,
    extension: null,
    isLive: null,
    isClip: null,
    isSuggestionsOpen: false,
    preview: null,
    program: '',
    season: null,
    sortOrder: '',
    orderBy: null,
    nodes: '',
    keywords: '',
    tagsMode: 'any',
    excludedNids: '',
  }

  preview = debounce(e => {
    this.setState({
      loadingPreview: true,
    })
    this.fetchPreviewData().then(result => {
      this.setState({
        preview: result.data,
        loadingPreview: false,
      })
    })
  }, 1000)

  fetchPreviewData = () => {
    return apolloClient.query({
      query: graphql(`
        query search(
          $assetType: VideoAssetType
          $isLive: Boolean
          $nodeNids: [String]
          $nodes: String
          $season: Int
          $sortOrder: String
          $tags: String
          $tagsMode: String
        ) {
          videoAssetSearch(
            nodeNids: $nodeNids
            tags: $tags
            nodes: $nodes
            isLive: $isLive
            sortOrder: $sortOrder
            type: $assetType
            season: $season
            tagsMode: $tagsMode
          ) {
            totalHits
            videoAssets {
              title
              image
              season
            }
          }
        }
      `),
      variables: {
        isLive: this.state.isLive,
        nodeNids: this.state.program,
        nodes: this.state.nodes,
        nodeNidsMode: 'any',
        nodesMode: 'any',
        season: parseInt(this.state.season, 10),
        sortOrder: this.state.sortOrder,
        tags: this.state.tags,
        tagsMode: this.state.tagsMode,
        assetType: ASSETTYPES[this.state.assetType],
      },
    })
  }

  componentDidMount() {
    window.contentfulExtension &&
      window.contentfulExtension.init(extension => {
        const values = extension.field.getValue() || {}
        extension.window.updateHeight(500)

        this.setState(
          {
            assetType: values.assetType,
            program: values.program || '',
            sortOrder: values.sortOrder || '',
            season: values.season || '',
            tags: values.tags || '',
            tagsMode: values.tagsMode,
            isLive: values.isLive,
            extension,
            excludedNids: values.excludedNids || '',
            nodes: values.nodes || '',
            orderBy: values.orderBy,
          },
          () => {
            this.preview()
          }
        )
      })
  }
  onFieldChange = (field, value) => {
    this.setState(
      {
        [field]: value,
      },
      () => {
        if (this.state.extension) {
          const {
            assetType,
            program,
            sortOrder,
            isLive,
            season,
            tags,
            tagsMode,
            excludedNids,
            nodes,
            orderBy,
          } = this.state
          this.state.extension.field
            .setValue({
              assetType,
              program,
              sortOrder,
              isLive,
              season,
              tags,
              tagsMode,
              excludedNids,
              nodes,
              orderBy,
            })
            .catch(error => {
              console.log('Error: ' + JSON.stringify(error))
            })
        }
        this.preview()
      }
    )
  }
  render() {
    const {
      assetType,
      isLive,
      preview,
      program,
      season,
      tags,
      tagsMode,
      sortOrder,
      excludedNids,
      nodes,
      orderBy,
    } = this.state
    return (
      <TagListContainer>
        <Flex style={{ minWidth: 400 }}>
          <Row>
            <ColHeader> Program </ColHeader>
            <InputWrap>
              {' '}
              <input value={program} onChange={e => this.onFieldChange('program', e.target.value)} />{' '}
            </InputWrap>
          </Row>
          <Row>
            <ColHeader> Tags </ColHeader>
            <InputWrap>
              {' '}
              <input value={tags} onChange={e => this.onFieldChange('tags', e.target.value)} />{' '}
            </InputWrap>
          </Row>
          <Row>
            <ColHeader> Exclude node nids </ColHeader>
            <InputWrap>
              <input value={excludedNids} onChange={e => this.onFieldChange('excludedNids', e.target.value)} />
            </InputWrap>
          </Row>
          <Row>
            <ColHeader> Tags mode </ColHeader>
            <div>
              <RadioGroup>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('tagsMode', 'any')
                      }}
                      value="option1"
                      checked={tagsMode === 'any'}
                    />
                    Any
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('tagsMode', 'all')
                      }}
                      value="option2"
                      checked={tagsMode === 'all'}
                    />
                    All
                  </RadioLabel>
                </div>
              </RadioGroup>
            </div>
          </Row>
          <Row>
            <ColHeader> Nodes </ColHeader>
            <InputWrap>
              <input value={nodes} onChange={e => this.onFieldChange('nodes', e.target.value)} />
            </InputWrap>
          </Row>
          <Row>
            <ColHeader> Season </ColHeader>
            <InputWrap>
              {' '}
              <input value={season || ''} onChange={e => this.onFieldChange('season', e.target.value)} />{' '}
            </InputWrap>
          </Row>
          <Row>
            <ColHeader>Order by</ColHeader>
            <div>
              <RadioGroup>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="checkbox"
                      onChange={e => {
                        this.onFieldChange('orderBy', e.target.checked ? 'episode' : null)
                      }}
                      value="option1"
                      checked={orderBy === 'episode'}
                    />
                    Episode number
                  </RadioLabel>
                </div>
              </RadioGroup>
            </div>
          </Row>
          <Row>
            <ColHeader> Sort order </ColHeader>
            <div>
              <RadioGroup>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('sortOrder', 'asc')
                      }}
                      value="option1"
                      checked={sortOrder === 'asc'}
                    />
                    Asc
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('sortOrder', 'desc')
                      }}
                      value="option2"
                      checked={sortOrder === 'desc'}
                    />
                    Desc
                  </RadioLabel>
                </div>
              </RadioGroup>
            </div>
          </Row>
          <Row>
            <ColHeader>Asset Type</ColHeader>
            <div>
              <RadioGroup>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('assetType', ASSETTYPES.EPISODE)
                      }}
                      value="option1"
                      checked={assetType === ASSETTYPES.EPISODE}
                    />
                    Episode
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('assetType', ASSETTYPES.CLIP)
                      }}
                      value="option2"
                      checked={assetType === ASSETTYPES.CLIP}
                    />
                    Clip
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('assetType', ASSETTYPES.VIRTUAL_CHANNEL)
                      }}
                      value="option3"
                      checked={assetType === ASSETTYPES.VIRTUAL_CHANNEL}
                    />
                    Virtual channel
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('assetType', ASSETTYPES.ANY)
                      }}
                      value="option4"
                      checked={assetType === ASSETTYPES.ANY}
                    />
                    Any
                  </RadioLabel>
                </div>
              </RadioGroup>
            </div>
          </Row>
          <Row>
            <ColHeader> Live </ColHeader>
            <div>
              <RadioGroup>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('isLive', true)
                      }}
                      value="option1"
                      checked={isLive === true}
                    />
                    Yes
                  </RadioLabel>
                </div>
                <div className="radio">
                  <RadioLabel>
                    <input
                      type="radio"
                      onChange={() => {
                        this.onFieldChange('isLive', false)
                      }}
                      value="option2"
                      checked={isLive === false}
                    />
                    No
                  </RadioLabel>
                </div>
              </RadioGroup>
            </div>
          </Row>
        </Flex>
        <Preview preview={preview} loading={this.state.loadingPreview} />
      </TagListContainer>
    )
  }
}
