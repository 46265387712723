import styled from 'styled-components'

export const RadioGroup = styled.div`
  padding: 5px 5px 15px 5px;
`
export const RadioLabel = styled.label`
  font-weight: 500;
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
`
export const Col = styled.div`
  text-align: center;
  padding: 10px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
`
export const ColHeader = styled.div`
  display: inline-block;
  min-width: 150px;
`
