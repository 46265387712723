export const CARD_TYPES = {
  pageCard: {
    contentTypeId: 'pageCard',
    displayName: 'Page Card',
  },
  programCard: {
    contentTypeId: 'programCard',
    displayName: 'Program Card',
  },
  staticPageLinkCard: {
    contentTypeId: 'staticPageLinkCard',
    displayName: 'Static Page Link Card',
  },
  urlCard: {
    contentTypeId: 'urlCard',
    displayName: 'URL Card',
  },
  playableCard: {
    contentTypeId: 'playableCard',
    displayName: 'Playable Card',
  },
}
