import React from 'react'
import { imageProxyUrl } from '../../modules/image-proxy'
import { PreviewItem, PreviewList, PreviewPublishedText, PreviewText } from './style'
import { Spinner, SectionHeading, Card, HelpText, Subheading } from '@contentful/forma-36-react-components'
export default ({ preview, loading }) => {
  const videoAssets = preview && preview.videoAssetSearchForPlugin && preview.videoAssetSearchForPlugin.data
  const totalHits = (preview && preview.videoAssetSearchForPlugin && preview.videoAssetSearchForPlugin.total_hits) || 0
  const showPreview = totalHits > 0

  return (
    <div>
      <SectionHeading>Förhandsvisning</SectionHeading>
      <HelpText>This query will return {totalHits} assets</HelpText>

      {loading && <Spinner />}

      {showPreview && (
        <Card>
          <PreviewText>Preview - First 8 Hits</PreviewText>
          <PreviewList>
            {videoAssets.slice(0, 8).map(asset => (
              <PreviewItem key={asset.id}>
                <img src={imageProxyUrl(asset.image, 100)} alt={asset.title} />
                <Subheading element="h3">{asset.title}</Subheading>
                <PreviewPublishedText>{asset.publishedDateTime}</PreviewPublishedText>
              </PreviewItem>
            ))}
          </PreviewList>
        </Card>
      )}
    </div>
  )
}
