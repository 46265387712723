import React, { useState, useRef, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getListStyle, getItemStyle, reorder } from '../../utils/multipleEntryUtils'
import useContentfulExtension from '../hooks/useContentfulExtension'
import VideoAssetFinder from '../VideoAssetFinder'
import PlaylistItem from './PlaylistItem'
import debounce from '../../debounce'
import { AddCircle } from 'styled-icons/material/AddCircle'
import { Flex } from 'rebass'
import { Trash } from 'styled-icons/boxicons-solid/Trash'
import ErrorBoundary from '../ErrorBoundary'
import { AddButton, NewsListContainer, DeleteEverything } from './style'
import { ApolloProvider } from 'react-apollo'
import apolloClient from '../../graphql/apolloClient'

const LIST_TYPES = {
  TOPNEWS: 'TOPNEWS',
  PRIO: 'PRIONEWS',
}

export default () => {
  const extension = useContentfulExtension()
  const videoAssetFinder = useRef(null)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    if (extension) {
      const deserialized = extension.field.getValue() || []
      setItems(deserialized)
      extension.window.updateHeight(800)
    }
  }, [extension])

  const onRemove = (listType, id) => {
    setItems(items.filter(pa => pa.id !== id))
  }

  const onVideoAssetSelected = videoAsset => {
    const playlistItem = {
      id: videoAsset.id,
      title: videoAsset.title,
      pitch: videoAsset.description,
      images: {
        main16x9: videoAsset.image,
      },
    }
    setItems([...items, playlistItem])
  }
  const saveValueDebounced = debounce(serializedData => {
    extension.field.setValue(serializedData)
  }, 600)

  useEffect(() => {
    if (extension) {
      // only persist some fields. UI-specific fields are of no interest´
      const serializedData = [
        ...items.map(({ id, title, pitch, images, videoAsset }) => ({
          id,
          title,
          pitch,
          images,
        })),
      ]
      saveValueDebounced(serializedData)
    }
  }, [items])

  const clearAll = () => {
    setItems([])
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const reorderedEntries = reorder(items, result.source.index, result.destination.index)
    setItems(reorderedEntries)
  }

  return (
    <ApolloProvider client={apolloClient}>
      <NewsListContainer>
        <DeleteEverything>
          <Trash
            onClick={() => {
              extension.dialogs
                .openConfirm({
                  title: 'Rensa innehåll?',
                  message: 'Vill du ta bort allt prioriterat innehåll?',
                  intent: 'negative',
                  confirmLabel: 'Rensa!',
                  cancelLabel: 'Avbryt',
                })
                .then(result => {
                  if (result) {
                    clearAll()
                  }
                })
            }}
          />
        </DeleteEverything>
        <ErrorBoundary>
          <Flex width={1} align-items="center">
            <h2 style={{ width: '80%', display: 'inline-block' }}>Videos</h2>
            <AddButton
              onClick={() => {
                setShowAddDialog(true)
              }}
            >
              <AddCircle />
            </AddButton>
          </Flex>
          {showAddDialog && (
            <div style={{ marginBottom: 16 }}>
              <VideoAssetFinder
                ref={videoAssetFinder}
                parentExtension={extension}
                onCloseResults={() => setShowAddDialog(false)}
                onVideoAssetSelected={videoAsset => {
                  onVideoAssetSelected(videoAsset)
                  setShowAddDialog(false)
                }}
                smallLayout
              />
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={LIST_TYPES.TOPNEWS}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={{ ...getListStyle(snapshot.isDraggingOver), minHeight: 100 }}>
                  {items.map((newsPost, index) => (
                    <Draggable key={newsPost.id} draggableId={newsPost.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <PlaylistItem
                            extension={extension}
                            newsPost={newsPost}
                            onRemove={() => {
                              onRemove(LIST_TYPES.TOPNEWS, newsPost.id)
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ErrorBoundary>
      </NewsListContainer>
    </ApolloProvider>
  )
}
