// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 0

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '4px 0 0 0',
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFF',
  borderBottom: '1px solid #D3D3D3',
  // styles we need to apply on draggables
  ...draggableStyle,
})

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'rgb(234, 234, 234)' : '#FFF',
  padding: grid,
  width: '100%',
})
