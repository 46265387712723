import React from 'react'
import { Box, Flex } from 'rebass'
import { SectionHeading, Paragraph, Typography, Subheading, Icon } from '@contentful/forma-36-react-components'
import { format } from 'date-fns'
import PlatformDisplay from '../PlatformDisplay'
import { STATUS_DATA } from '../../utils/SchedulingStatus'

export default ({ panel }) => {
  const { internalName, title, publishFrom, publishTo, schedulingStatus, platforms, panelContentType } = panel
  let displayName = panelContentType // We want the handling of panel types to be dynamic. We don't bother to display a nice name. If that is needed, we could perhaps fetch it from Contentful.
  return (
    <Flex>
      <Box width={2 / 3}>
        <Typography>
          <Flex alignItems="center">
            <Subheading>{title}</Subheading>
          </Flex>
          <SectionHeading>{internalName}</SectionHeading>
          <Paragraph>{displayName}</Paragraph>
        </Typography>
      </Box>
      <Box width={1 / 3}>
        <Typography>
          <table>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <SectionHeading>
                    <strong>
                      <Icon
                        icon="Clock"
                        size="small"
                        color="secondary"
                        style={{ display: 'inline-block', verticalAlign: 'top' }}
                      />
                      <span
                        style={{
                          color: STATUS_DATA[schedulingStatus].color,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          paddingLeft: 4,
                        }}
                      >
                        {schedulingStatus}
                      </span>
                    </strong>
                  </SectionHeading>
                </td>
              </tr>
              <tr>
                <td>{publishFrom && <strong>Från</strong>}</td>
                <td>{publishFrom && format(publishFrom, 'YYYY-MM-DD HH:mm')}</td>
              </tr>
              <tr>
                <td>{publishTo && <strong>Till</strong>}</td>
                <td> {publishTo && format(publishTo, 'YYYY-MM-DD HH:mm')}</td>
              </tr>
              <tr>
                <td>
                  <PlatformDisplay {...platforms} />
                </td>
              </tr>
            </tbody>
          </table>
        </Typography>
      </Box>
    </Flex>
  )
}
