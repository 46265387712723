import React, { useState, useEffect } from 'react'
import useContentfulExtension from '../hooks/useContentfulExtension'
import Select from 'react-select'
import VideoAssetFinder from '../VideoAssetFinder'
import PageSelector from '../PageSelector'
import styled from 'styled-components'
import CDPSelector from '../CDPSelector'

const ShowcaseTargetSelectorContainer = styled.div`
  padding-bottom: 80px;
`

const Program = styled(CDPSelector)`
  margin-top: 16px;
`

const TARGET_TYPES = {
  PROGRAM: 'program',
  PAGE: 'page',
  VIDEO: 'video',
  URL: 'url',
}
const options = [
  { value: TARGET_TYPES.PROGRAM, label: 'Program' },
  { value: TARGET_TYPES.PAGE, label: 'Page' },
  { value: TARGET_TYPES.VIDEO, label: 'Video' },
  { value: TARGET_TYPES.URL, label: 'Url' },
]

export default () => {
  const extension = useContentfulExtension()

  const [type, setType] = useState(null)
  const [value, setValue] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)

  const handleExpiredAsset = async () => {
    // Entity has previously saved reference to asset that has been unpublished/removed
    // We need to remove this old data so contentful validation works as intended
    await extension.field.removeValue()
  }

  useEffect(() => {
    if (extension) {
      extension.window.startAutoResizer()
      if (value && type) {
        extension.field.setValue({
          type,
          value,
        })
      } else {
        extension.field.removeValue()
      }
    }
  }, [value, type])

  useEffect(() => {
    if (extension) {
      const val = extension.field.getValue()
      if (!val || !val.value) {
        return
      }
      setValue(val.value)
      setType(val.type)
      if (val.type) {
        setSelectedValue(options.find(opt => opt.value === val.type))
      }
    }
  }, [extension])
  return (
    <ShowcaseTargetSelectorContainer>
      <Select
        value={selectedValue}
        onChange={opt => {
          setType(opt.value)
          setSelectedValue(opt)
          setValue(null)
        }}
        options={options}
      />
      {type === TARGET_TYPES.VIDEO && (
        <VideoAssetFinder
          selectedId={`${value}`}
          parentExtension={extension}
          handleExpiredAsset={handleExpiredAsset}
          onVideoAssetSelected={video => setValue(video ? video.id : null)}
        />
      )}
      {type === TARGET_TYPES.PROGRAM && <Program initialValue={value} setEntryValue={program => setValue(program)} />}
      {type === TARGET_TYPES.PAGE && (
        <PageSelector onPageSelected={page => setValue(page)} initialSelectedValue={value} isStandAlone={false} />
      )}

      {type === TARGET_TYPES.URL && (
        <input
          style={{ margin: '16px 0', height: 32, width: '100%', fontSize: 18, padding: 4 }}
          type="text"
          value={value}
          onChange={e => {
            setValue(e.target.value)
          }}
        />
      )}
    </ShowcaseTargetSelectorContainer>
  )
}
