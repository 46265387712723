import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box } from 'rebass'
import useContentfulExtension from '../hooks/useContentfulExtension'
import Select from 'react-select'
import { FieldExtensionSDK } from 'contentful-ui-extensions-sdk'
import { get } from 'lodash'
import ImagePreview from './ImagePreview'
import fetchCometMetaData from './fetchCometMetaData'
import { getMain16x7, getMain16x9Annotated, getCoverSE } from '../CometFieldSelector/contentfulHelper'
import Loader, { LoaderOverlay } from '../styled/Loader'
import { Note } from '@contentful/forma-36-react-components'

const fetchOptions = async (sdk: FieldExtensionSDK): Promise<any[]> => {
  try {
    const limit = 1000
    let allItems: any [] = []
    let total = 10000 // set in first loop

    while (allItems.length < total) {
      const result = await sdk.space
          .getEntries({
            content_type: 'program',
            skip: allItems.length,
            include: 10,
            limit: limit,
            order: 'fields.nid'
          })
      allItems = [...allItems, ...result.items]
      total = result.total
    }

    if (allItems.length > 0) {
      const options: any[] = allItems
        .map((program: any) => ({
          label: `${get(program, 'fields.name.en-US')} (${get(program, 'fields.nid.en-US') || 'NID saknas på cdp'})`,
          value: get(program, 'fields.nid.en-US')
        }))
      return options
    }
  } catch (err) {
    console.error(err)
  }
  throw new Error('Fetch failed')
}

const fetchCometData = async (nid: string, apiKey: string) => {
  const data = await fetchCometMetaData(nid, apiKey)
  if (data) {
    return {
      main: getMain16x7(data),
      cover: getCoverSE(data),
      main16x9Annotated: getMain16x9Annotated(data)
    }
  }
  return null
}

export default ({ initialValue, setEntryValue, className }: { initialValue: string, setEntryValue: Function, className: string }) => {
  const sdk: FieldExtensionSDK = useContentfulExtension()
  const apiKey = useRef<string>()
  const [cometImages, setCometImages] = useState()
  const [loadingImages, setLoadingImages] = useState()
  const [selected, setSelected] = useState()
  const [fetchingPrograms, setFetchingPrograms] = useState(false)
  const [programOptions, setProgramOptions] = useState<any[]>([])
  const [initialized, setInitialzed] = useState(false)
  useEffect(() => {
    if (sdk) {
      setFetchingPrograms(true)
      apiKey.current = get(sdk,'parameters.installation.apikey')
      fetchOptions(sdk).then(options => {
        sdk.window.startAutoResizer()
        const option = options.find((o => (o.value === sdk.field.getValue()) || o.value === initialValue))
        if (option) {
          setSelected(option)
        }
        setProgramOptions(options)
        setInitialzed(true)
        setFetchingPrograms(false)
      })
    }
  }, [sdk])
  useEffect(() => {
    if (selected && selected.value && apiKey && apiKey.current) {
      setLoadingImages(true)
      setCometImages(null)
      fetchCometData(selected.value, apiKey.current).then(data => {
        setLoadingImages(false)
        if (data) {
          setCometImages({
            main4x3: `https://img.cmore.incomet.io/${data.main}/crop4x3.jpg`,
            main16x7: `https://img.cmore.incomet.io/${data.main}/crop16x7.jpg`,
            main16x9: `https://img.cmore.incomet.io/${data.main}/crop16x9.jpg`,
            main2x3Annotated: `https://img.cmore.incomet.io/${data.cover}/crop2x3.jpg`,
            main16x9Annotated: `https://img.cmore.incomet.io/${data.main16x9Annotated}/crop16x9.jpg`,
          })
        }
      })
    }
  }, [selected])
  if (!sdk) {
    return null
  }
  return (
    <Flex width={1} flexWrap="wrap" className={className}>
      <Box width={1}>
        {fetchingPrograms &&
          <div>
            <Loader /> <span style={{ marginLeft: 16 }}>Laddar...  </span>
          </div>
        }
        {!fetchingPrograms &&
          <Select
            isLoading={!initialized}
            options={programOptions}
            onChange={opt => {
              setSelected(opt)
              if (setEntryValue) {
                setEntryValue(opt.value)
              } else {
                if (opt.value && opt.value !== sdk.field.getValue()) {
                  sdk.field.setValue(opt.value)
                }
              }
            }}
            value={selected}
          />
        }
      </Box>
      <Flex justifyContent="center" alignItems="center" width={1} style={{ minHeight: 200 }}>
        {loadingImages &&
          <div style={{ textAlign: 'center' }}>  <Loader /> <br />
            ...hämtar comet-data
          </div>
        }
        {!loadingImages && selected &&
          <div key={selected || 'empty'}>
            {cometImages && <ImagePreview images={cometImages} />}
            {!cometImages && <Note> hittade inga bilder i comet för {selected.value}</Note>}
          </div>
        }
      </Flex>
    </Flex>
  )
}
