import React from 'react'
import { VideoListItem, ErrorMessage } from './style'
import { Error as ErrorIcon } from 'styled-icons/material/Error'
const SPACE_ID = process.env.REACT_APP_SPACE_ID

export default ({ sysId, onRemoveItem }) => (
  <VideoListItem>
    <ErrorMessage>
      <header>
        {' '}
        <ErrorIcon style={{ width: 25, color: 'red', marginRight: 5 }} />
        Fel vid hämtning av video.{' '}
      </header>
      <p>
        Videon har importerats men publiceringen misslyckades. Se över videon och säkerställ att den är korrekt
        publicerad i Contentful. <br />
        <span style={{ color: 'red' }}> Om videon är en liveström så kan felet bero på att sändningen avslutats. </span>
        <br />
        <a
          href={`https://app.contentful.com/spaces/${SPACE_ID}/entries/${sysId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Klicka här för att gå till felaktig asset{' '}
        </a>
      </p>
      <button
        style={{ float: 'right' }}
        onClick={() => {
          onRemoveItem(sysId)
        }}
      >
        {' '}
        Ta Bort{' '}
      </button>
    </ErrorMessage>
  </VideoListItem>
)
