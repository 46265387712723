import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getListStyle, getItemStyle, reorder } from '../../utils/multipleEntryUtils'
import { parsePreviewData, parseManagementData } from './parseCardContentfulData'
import * as contentfulManagementClient from '../../modules/contentful-management-client'
import * as contentfulPreviewClient from '../../modules/contentful-preview-client'
import * as contentfulDeliveryClient from '../../modules/contentful-delivery-client' // only used in dev.
import Loader, { LoaderOverlay } from '../styled/Loader'
import { CardButton, RefreshButton } from './style'
import CardEditorItem from './CardEditorItem'
import _get from 'lodash.get'
import uuidv1 from 'uuid/v1'
import { Refresh } from 'styled-icons/material/Refresh'

const REFRESH_INTERVAL = 10000
const cardTypes = ['pageCard', 'programCard', 'playableCard', 'urlCard']
const allowedContentTypes = 'programCard,playableCard,urlCard,pageCard'
let refreshInterval

export default class CardsEditor extends Component {
  state = {
    entries: [],
    currentEntryId: null,
    extension: null,
    showCreateAndAdd: false,
    createdLink: null,
  }

  /**
   * Only run when initialized through contentful. For docs on extension capabilities check the UI Extension SDK
   */
  setStateFromExtension = extension => {
    const fieldValues = extension.field.getValue() || []
    this.setState(
      {
        extension: extension,
        token: _get(extension, 'parameters.installation.managementToken'),
        deliveryToken: _get(extension, 'parameters.installation.deliveryToken'),
        environment: extension.ids.environment,
        currentEntryId: extension.entry.getSys().id,
        entries: fieldValues.map(link => {
          return Object.assign({}, link, {
            loading: true,
            fields: {},
          })
        }),
      },
      () => {
        this.fetchPublishedStatus({
          environment: this.state.environment,
          deliveryToken: this.state.deliveryToken,
          entries: this.state.entries,
          token: this.state.token,
        })
      }
    )
  }
  /**
   * Get field values & system metadata for linked fields status through the preview API.
   */
  fetchPublishedStatus = async ({ entries, token, deliveryToken, environment }) => {
    const ids = entries.map(e => e.sys.id)
    const previewData = await contentfulPreviewClient.getEntryData({
      ids,
      token: deliveryToken,
      environment,
    })
    const nextStateEntries = entries.map(entry => {
      const previewMetaData = previewData.find(md => md.sys.id === entry.sys.id)
      const originalEntry = entries.find(ed => ed.sys.id === entry.sys.id)
      const parsedEntry = parsePreviewData({ previewMetaData, originalEntry })
      return { ...parsedEntry, loading: false, expanded: false }
    })
    this.setState({
      entries: nextStateEntries,
    })
  }
  createRefreshInterval = () => {
    return setInterval(this.refresh, REFRESH_INTERVAL)
  }
  onExpandCard = index => {
    const nextStateEntries = [...this.state.entries]
    nextStateEntries[index].expanded = !nextStateEntries[index].expanded
    this.setState({
      entries: nextStateEntries,
    })
  }

  componentDidMount() {
    if (window.contentfulExtension) {
      window.contentfulExtension.init(extension => {
        this.setStateFromExtension(extension)
        extension.field.onValueChanged(() => {
          this.refresh()
        })

        extension.window.updateHeight(700)
      })
      refreshInterval = this.createRefreshInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(refreshInterval)
  }

  getEntryLinks = entries => {
    return entries.map(entry => ({
      sys: { type: 'Link', linkType: 'Entry', id: `${entry.sys.id}` },
    }))
  }

  refresh = () => {
    if (this.state.dragging) {
      return
    }

    const { extension, environment, token, deliveryToken } = this.state
    const entries = extension
      ? extension.field.getValue().map(link => {
          return Object.assign({}, link, { loading: true, fields: {} }) // prod mode
        })
      : this.state.entries // dev mode
    this.fetchPublishedStatus({ entries, environment, token, deliveryToken })
  }

  onRemoveItem = sysId => {
    const remainingItems = this.state.entries.filter(entry => entry.sys.id !== sysId)
    this.setState({
      entries: remainingItems,
    })
    const entryLinks = this.getEntryLinks(remainingItems)
    if (this.state.extension) {
      this.state.extension.field.setValue(entryLinks)
    }
  }
  /**
   *
   */
  createEntry = ({ contentType, fields, accessToken, environment, deliveryToken, shouldPublish = false }) => {
    const id = uuidv1()
    const { extension } = this.state
    this.setState({
      resultsLoading: true,
    })
    contentfulManagementClient
      .createEntry({
        contentType,
        id,
        fields,
        shouldPublish,
        environment,
        token: accessToken,
      })
      .then(async createdResult => {
        const createdItems = await contentfulManagementClient.getEntryData({
          ids: [createdResult.sys.id],
          token: accessToken,
          environment,
        })
        const deliveryMetaData = await contentfulDeliveryClient.getEntriesByIds({
          ids: [createdResult.sys.id],
          accessToken: deliveryToken,
          environment,
        })
        const entryWithImageData = parseManagementData({
          managementMetaData: createdItems[0],
          deliveryMetaData: deliveryMetaData[0],
          createdResult,
        })
        const newEntries = this.state.entries.concat([entryWithImageData])
        this.setState({ entries: newEntries, resultsLoading: false })

        if (extension) {
          const entryLinks = this.getEntryLinks(newEntries)
          extension.field.setValue(entryLinks)
          this.refresh()
        }
      })
      .catch(err => {
        extension.notifier.error('Misslyckades med att skapa kort')
        console.error(err)
        this.setState({
          resultsLoading: false,
        })
      })
  }
  onRemoveItem = sysId => {
    const remainingItems = this.state.entries.filter(entry => entry.sys.id !== sysId)
    this.setState({
      entries: remainingItems,
    })
    const entryLinks = this.getEntryLinks(remainingItems)
    if (this.state.extension) {
      this.state.extension.field.setValue(entryLinks)
    }
  }
  onDragStart = () => {
    this.setState({
      dragging: true,
    })
    clearInterval(refreshInterval)
  }
  onDragEnd = result => {
    this.setState({
      dragging: false,
    })
    refreshInterval = this.createRefreshInterval()
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const reorderedEntries = reorder(this.state.entries, result.source.index, result.destination.index)
    if (this.state.extension) {
      this.state.extension.field.setValue(this.getEntryLinks(reorderedEntries))
    }
    this.setState({
      entries: reorderedEntries,
    })
  }
  linkExistingEntries = () => {
    if (this.state.extension) {
      this.state.extension.dialogs
        .selectSingleEntry({
          locale: 'en-US',
          contentTypes: allowedContentTypes.split(','),
        })
        .then(selectedEntry => {
          if (!selectedEntry) {
            return
          }
          const nextStateEntries = [...this.state.entries, selectedEntry]
          this.setState({
            entries: nextStateEntries,
          })
          this.state.extension.field.setValue(this.getEntryLinks(nextStateEntries))
        })
    }
  }

  moveCard = async ({ id, targetField, append = true }) => {
    const { extension } = this.state
    if (targetField === extension.field.id) {
      extension.notifier.error(`Detta kort finns redan i ${extension.field.id}`)
      return
    }
    const currentSourceVal = extension.field.getValue()
    const currentDestinationVal = extension.entry.fields[targetField].getValue()
    if (Array.isArray(currentDestinationVal)) {
      const entry = {
        sys: {
          id: id,
          linkType: 'Entry',
          type: 'Link',
        },
      }

      const newValue = append ? [...currentDestinationVal, entry] : [entry, ...currentDestinationVal]
      extension.entry.fields[targetField].setValue(newValue)
      extension.field.setValue(currentSourceVal.filter(entry => entry.sys.id !== id))
      this.refresh()
    } else {
      extension.notifier.error('Det gick inte att flytta kort till det valda fältet')
    }
  }

  duplicateItem = async ({ contentType, id }) => {
    const { environment, token, deliveryToken, extension } = this.state
    const items = await contentfulPreviewClient.getEntryData({
      ids: [id],
      environment,
      includeStatus: false,
    })
    if (!items || items.length < 1) {
      extension.notifer.error('Misslyckades att hämta data för duplicering')
    }
    const fields = items[0].fields
    if (!fields) {
      extension.notifer('Ett fel uppstod')
      return
    }
    /* Management API always uses localized fields, defaults to en-US.
    even if   no locale is used (as in our case).
    So we need to format data accordingly when inserting */

    const localizedFields = {}
    Object.keys(fields).forEach(key => {
      /* handle linked assets (linked through .sys object) differently than
      primitive value fields   */
      if (!fields[key].sys) {
        localizedFields[key] = {
          'en-US': fields[key],
        }
      } else {
        localizedFields[key] = {
          'en-US': {
            sys: {
              id: fields[key].sys.id,
              linkType: 'Asset',
              type: 'Link',
            },
          },
        }
      }
    })

    this.createEntry({
      contentType,
      fields: localizedFields,
      environment: environment,
      accessToken: token,
      deliveryToken: deliveryToken,
      shouldPublish: true,
    })
  }

  onNavigateToEntry = ({ e, entryId }) => {
    if (this.state.extension) {
      e.preventDefault()
      this.state.extension.navigator.openEntry(entryId, { slideIn: true })
    }
  }

  render() {
    const {
      entries,
      currentEntryId,
      resultsLoading,
      environment,
      token,
      deliveryToken,

      extension,
    } = this.state

    // I know, I know...
    const IS_MOSAIC = extension && extension.contentType.sys.id === 'showcaseMosaic'

    return (
      <div style={{ position: 'relative' }}>
        <RefreshButton onClick={this.refresh}>
          <Refresh width={32} height={32} />
        </RefreshButton>
        {resultsLoading && <LoaderOverlay />}
        <div>
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {entries.map((card, index) => (
                    <Draggable key={`${card.sys.id}_${index}`} draggableId={`${card.sys.id}_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {!card || card.loading ? (
                            <div>
                              <Loader style={{ margin: '0 10px 0 5px' }} />
                              Loading ...
                            </div>
                          ) : (
                            <CardEditorItem
                              index={index}
                              style={{
                                maxHeight: snapshot.isDragging ? '44px' : 'inherit',
                              }}
                              isMosaic={IS_MOSAIC}
                              onMove={this.moveCard}
                              onExpandCard={this.onExpandCard}
                              onNavigateToEntry={this.onNavigateToEntry}
                              environment={environment}
                              card={card}
                              currentField={extension.field.id}
                              entryId={currentEntryId}
                              duplicateItem={this.duplicateItem}
                              onRemoveItem={this.onRemoveItem}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {cardTypes.map(c => (
          <CardButton
            key={c}
            onClick={() => {
              this.createEntry({
                contentType: c,
                fields: {
                  internalName: {
                    'en-US': `${c} created by extension`,
                  },
                },
                environment: environment,
                accessToken: token,
                deliveryToken: deliveryToken,
              })
            }}
          >
            Add {c}
          </CardButton>
        ))}
        <CardButton style={{ backgroundColor: '#4caf50' }} onClick={this.linkExistingEntries}>
          Link existing entries
        </CardButton>
      </div>
    )
  }
}
