import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getListStyle, getItemStyle, reorder } from '../../utils/multipleEntryUtils'
import { ApolloProvider } from 'react-apollo'
import apolloClient from '../../graphql/apolloClient'
import VideoAssetListItem from './VideoAssetListItem'
import VideoAssetFinder from '../VideoAssetFinder'
import Modal from 'react-modal'
import { createEntry } from '../../modules/contentful-management-client'
import * as contentfulDeliveryClient from '../../modules/contentful-delivery-client'
import Loader, { LoaderOverlay } from '../styled/Loader'
import _get from 'lodash.get'
const VIDEO_ASSET_CONTENT_TYPE_ID = 'videoAsset'

export default class VideoAssetList extends Component {
  state = {
    entries: [],
    extension: null,
    showCreateAndAdd: false,
  }

  // Merges metadata for field with the barebones "link"-object which initally gets provided by contentful.
  fetchMetaDataForEntries = async ({ entries, accessToken, environment }) => {
    const ids = entries.map(e => e.sys.id)
    // get linked etnries
    const entryMetaData = await contentfulDeliveryClient.getEntriesByIds({
      ids,
      accessToken,
      environment,
    })
    const nextStateEntries = this.state.entries.map(entry => {
      const metaData = entryMetaData.find(md => md.sys.id === entry.sys.id)
      return Object.assign(entry, metaData, {
        loading: false,
      })
    })
    this.setState({
      entries: nextStateEntries,
    })
  }

  async componentDidMount() {
    if (window.contentfulExtension) {
      window.contentfulExtension.init(extension => {
        extension.window.startAutoResizer()
        const fieldValues = extension.field.getValue() || []
        const accessToken = _get(extension, 'parameters.installation.managementToken')
        const environment = extension.ids.environment
        const deliveryToken = _get(extension, 'parameters.installation.deliveryToken')
        this.setState({
          accessToken,
          environment,
          entries: fieldValues.map(link => {
            return Object.assign({}, link, {
              loading: true,
              loadingGraphql: true,
              fields: {},
            })
          }),
          extension,
        })
        this.fetchMetaDataForEntries({
          entries: fieldValues,
          accessToken: deliveryToken,
          environment,
        })
      })
    }
  }
  onVideoAssetSelected = async videoAsset => {
    this.setState({
      showCreateAndAdd: false,
      resultsLoading: true,
    })
    // try to create the entry in the contentful space. These calls need to have a locale set, our default is en-US.
    try {
      const videoAssetEntry = await createEntry({
        token: _get(this.state.extension, 'parameters.installation.managementToken'),
        environment: this.state.extension.ids.environment,
        contentType: VIDEO_ASSET_CONTENT_TYPE_ID,
        id: videoAsset.id,
        fields: {
          title: {
            'en-US': videoAsset.title,
          },
          id: {
            'en-US': `${videoAsset.id}`,
          },
        },
      })

      // No exceptions - all good.
      let assetWithoutLocalization = Object.assign({}, videoAssetEntry, {
        sys: {
          id: videoAsset.id,
        },
        fields: { id: videoAsset.id },
      })
      this.setState(
        {
          entries: this.state.entries.concat([assetWithoutLocalization]).slice(),
          resultsLoading: false,
        },
        () => {
          // if we are not in dev mode, save the field.
          if (this.state.extension) {
            this.state.extension.field.setValue(this.getEntryLinks(this.state.entries))
          }
        }
      )
    } catch (creationFailure) {
      console.log('entry creation failed:', creationFailure)
      // Could also be - VersionMismatch?
      // Entry creation failed - most likely because selected asset already exists. If statusText is conflict, then just append field to the list.
      let error = JSON.parse(creationFailure.message)
      console.log('error as JSON', error)
      if (error.status === 409) {
        /* Put the selected video asset in the state and save it. Since it already exists it will work anyway,
         because contentful only cares about the id which is used to link references together. */
        this.setState(
          {
            entries: this.state.entries.concat([
              {
                sys: {
                  id: videoAsset.id,
                },
                fields: {
                  id: videoAsset.id,
                },
              },
            ]),
            resultsLoading: false,
          },
          () => {
            // if we are not in dev mode, save the field.
            if (this.state.extension) {
              this.state.extension.field.setValue(this.getEntryLinks(this.state.entries))
              this.state.extension.notifier.success(
                'Denna asset fanns redan uppladdad till contentful - Den är nu tillagd i listan'
              )
            }
          }
        )
      } else {
        this.state.extension.notifier.error('Ett fel uppstod - kontrollera att asset ej redan är uppladdad')
      }
    }
  }

  onCreateAndAdd = () => {
    this.setState({
      showCreateAndAdd: true,
    })
    if (this.state.extension) {
      this.state.extension.window.updateHeight(800)
    }
  }

  getEntryLinks = entries => {
    return entries.map(entry => ({
      sys: { type: 'Link', linkType: 'Entry', id: `${entry.sys.id}` },
    }))
  }

  onRemoveItem = sysId => {
    const remainingItems = this.state.entries.filter(entry => entry.sys.id !== sysId)
    this.setState({
      entries: remainingItems,
    })
    const entryLinks = this.getEntryLinks(remainingItems)
    if (this.state.extension) {
      this.state.extension.field.setValue(entryLinks)
    }
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedEntries = reorder(this.state.entries, result.source.index, result.destination.index)
    if (this.state.extension) {
      this.state.extension.field.setValue(this.getEntryLinks(reorderedEntries))
    }

    this.setState({
      entries: reorderedEntries,
    })
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { extension } = this.state
    return (
      <ApolloProvider client={apolloClient}>
        <div style={{ position: 'relative', minHeight: 700 }}>
          {this.state.resultsLoading && <LoaderOverlay />}
          <div style={{ maxHeight: 800, minHeight: 400, overflow: 'scroll' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {this.state.entries.map((item, index) => (
                      <Draggable key={item.sys.id} draggableId={item.sys.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            {item.loading ? (
                              <div>
                                {' '}
                                <Loader style={{ margin: '0 10px 0 5px' }} /> Loading Video{' '}
                              </div>
                            ) : (
                              <VideoAssetListItem
                                extension={extension}
                                sysId={item.sys ? item.sys.id : index}
                                onRemoveItem={this.onRemoveItem}
                                id={item.fields ? item.fields.id : null}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <button onClick={this.onCreateAndAdd}>{' Sök & Lägg till Video '}</button>

          <Modal
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={{
              content: {
                width: '100%',
                maxWidth: 900,
                overflowY: 'scroll',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-1%',
              },
            }}
            isOpen={this.state.showCreateAndAdd}
            contentLabel="Select Videoasset"
          >
            <VideoAssetFinder parentExtension={this.state.extension} onVideoAssetSelected={this.onVideoAssetSelected} />
            <button
              style={{
                marginLeft: 8,
                height: 44,
                background: '#FFF',
                borderColor: '#000',
                width: 100,
              }}
              onClick={() => {
                this.setState({ showCreateAndAdd: false })
              }}
            >
              Stäng
            </button>
          </Modal>
        </div>
      </ApolloProvider>
    )
  }
}
