import React from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import { STATUS_DATA } from '../../utils/SchedulingStatus'
import { Subheading, Tooltip, Tag, Button } from '@contentful/forma-36-react-components'
import { getPublishStatus, CONTENTFUL_STATUS } from '../../modules/contentful-status'
import PanelMinimalLayout from './PanelMinimalLayout'
const SPACE_ID = process.env.REACT_APP_SPACE_ID

const EditorCard = styled(Box)`
  padding-left: 4px;
  position: relative;
  min-height: 100px;
  border-left: ${props =>
    props.status && props.publishStatus !== CONTENTFUL_STATUS.DRAFT
      ? `3px solid ${STATUS_DATA[props.status].color}`
      : 'none'};

  .refreshButton {
    display: none;
  }

  &:hover .refreshButton {
    display: block;
  }
`

const CardEditorItem = ({
  panel,
  entryId,
  onRemoveItem,
  environment,
  index,
  onExpandCard,
  onNavigateToEntry,
  refresh,
}) => {
  const { schedulingStatus, panelContentType } = panel
  const editLinkTarget = `https://app.contentful.com/spaces/${SPACE_ID}/environments/${environment}/entries/${
    panel.sys.id
  }?previousEntries=${entryId}`

  if (!panelContentType) {
    return (
      <EditorCard key={panel.sys.id}>
        <Subheading>Varning - Felaktig eller avpublicerad panel hittades.</Subheading>
        <Flex width={1 / 2} pt={2} pb={2}>
          <Box width={1 / 2}>
            <Button size="small" href={editLinkTarget}>
              Gå till felaktig panel
            </Button>
          </Box>
          <Box width={1 / 2}>
            <Button
              size="small"
              icon="Close"
              buttonType="negative"
              onClick={() => {
                onRemoveItem(panel.sys.id)
              }}
            >
              Ta bort
            </Button>
          </Box>
        </Flex>
      </EditorCard>
    )
  }

  const contentfulPublishStatus = getPublishStatus(panel)
  return (
    <EditorCard key={panel.sys.id} publishStatus={contentfulPublishStatus} status={schedulingStatus}>
      <Flex direction="row">
        <Box width={0.85}>{!panel.expanded && <PanelMinimalLayout panel={panel} />}</Box>
        <Box width={0.15}>
          <Flex width={1} flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Box width={1} mb={2}>
              <PublishStatus status={contentfulPublishStatus} />
            </Box>
            <Box width={0.45}>
              <Tooltip content="Edit" containerElement="div" style={{ display: 'block' }} place="left">
                <Button
                  isFullWidth
                  size="small"
                  icon="Edit"
                  buttonType="primary"
                  onClick={e => {
                    onNavigateToEntry({ e, entryId: panel.sys.id })
                  }}
                />
              </Tooltip>
            </Box>
            <Box width={0.45}>
              <Tooltip content="Remove" containerElement="div" style={{ display: 'block' }} place="left">
                <Button
                  isFullWidth
                  size="small"
                  icon="Close"
                  buttonType="negative"
                  onClick={() => {
                    onRemoveItem(panel.sys.id)
                  }}
                />
              </Tooltip>
            </Box>
            <Box width={1} mt={2}>
              <Button isFullWidth buttonType="muted" size="small" onClick={refresh} className="refreshButton">
                Refresh
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </EditorCard>
  )
}

const PublishStatus = ({ status }) => {
  let tagType
  switch (status.displayName) {
    case 'PUBLISHED':
      tagType = 'positive'
      break
    case 'DRAFT':
      tagType = 'warning'
      break
    case 'UNKNOWN':
      tagType = 'negative'
      break
    case 'UPDATED':
      tagType = 'primary'
      break
    default:
      tagType = undefined
      break
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <Tag tagType={tagType}>{status.displayName}</Tag>
    </div>
  )
}

export default CardEditorItem
