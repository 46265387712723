import _get from 'lodash.get'
import _isEqual from 'lodash.isequal'

export const getName = response => {
  return _get((_get(response, 'versions') || []).find(({ language }) => language === 'sv'), 'title')
}

export const getDescription = response => {
  return _get((_get(response, 'versions') || []).find(({ language }) => language === 'sv'), 'synopsis.medium')
}

export const getAdCategory = response => {
  const isSingle = _get(response, 'type') === 'single'
  const tags = _get(response, [isSingle ? 'singleInfo' : 'seriesInfo', 'tags']) || []
  return _get(tags.find(({ type }) => type === 'Ad'), 'name')
}

export const getMain16x9Annotated = response => {
  const assets = _get(response, 'assets') || []
  return _get(assets.find(({ category, type }) => category === 'mainannotated16x9' && type === 'image'), 'id')
}

export const getMain16x7 = response => {
  const assets = _get(response, 'assets') || []
  return _get(assets.find(({ category, type }) => category === 'main' && type === 'image'), 'id')
}
export const getCoverSE = response => {
  const assets = _get(response, 'assets') || []
  return _get(
    assets.find(({ category, type, index }) => category === 'cover' && type === 'image' && index === 'CoverSE'),
    'id'
  )
}

export const getCategoryTags = response => {
  const isSingle = _get(response, 'type') === 'single'
  const t = _get(response, [isSingle ? 'singleInfo' : 'seriesInfo', 'tags']) || []
  return t.filter(({ type }) => type === 'Category')
}

export const setText = async (extension, field, value) => {
  if (extension.entry.fields[field].getValue() !== value) {
    extension.entry.fields[field].setValue(value)
  }
}

const nidify = str =>
  str
    .trim()
    .toLowerCase()
    .replace(/ & /g, '-')
    .replace(/ /g, '-')

const getOrCreateEntry = async (extension, contentType, query, entry) => {
  const foundEntries = (await extension.space.getEntries({
    content_type: contentType,
    ...query,
  })).items

  if (foundEntries.length > 0) {
    return foundEntries[0]
  }

  const createdEntry = await extension.space.createEntry(contentType, entry)
  return await extension.space.publishEntry(createdEntry)
}

export const setAdCategory = async (extension, adCategoryName) => {
  const currentValue = extension.entry.fields.adCategory.getValue()
  let currentAdCategory = undefined
  if (currentValue) {
    try {
      currentAdCategory = await extension.space.getEntry(currentValue.sys.id)
    } catch (err) {
      if (err.code !== 'NotFound') {
        throw err
      }
    }
  }

  if (!currentAdCategory || currentAdCategory.fields.name['en-US'] !== adCategoryName) {
    const nid = nidify(adCategoryName)
    const updatedAdCategory = await getOrCreateEntry(
      extension,
      'adCategory',
      { 'fields.nid': nid },
      {
        fields: {
          nid: { 'en-US': nid },
          name: { 'en-US': adCategoryName },
        },
      }
    )
    await extension.entry.fields.adCategory.setValue({
      sys: {
        type: 'Link',
        linkType: 'Entry',
        id: updatedAdCategory.sys.id,
      },
    })
  }
}

const IMAGE_CROPS = {
  main16x9Annotated: 'crop16x9.jpg',
  main16x7: 'crop16x7.jpg',
}

const getAssetId = (field, imageId) => `${field}-${imageId}`

const getAssetValue = async (extension, field, imageId) => {
  const assetId = getAssetId(field, imageId)
  try {
    // TODO: how to handle if the image changes in Comet, but we have an old version?
    const asset = await extension.space.getAsset(assetId)
    return {
      sys: {
        type: 'Link',
        linkType: 'Asset',
        id: asset.sys.id,
      },
    }
  } catch (err) {
    if (err.message !== "The resource could not be found.") {
      throw err
    }
  }

  const createdAsset = await extension.space.createAsset({
    sys: { id: assetId },
    fields: {
      title: {
        'en-US': `${field} från comet`,
      },
      file: {
        'en-US': {
          contentType: 'image/jpeg',
          fileName: `${imageId}.jpg`,
          upload: `https://img.cmore.incomet.io/${imageId}/${IMAGE_CROPS[field]}`,
        },
      },
    },
  })

  await extension.space.processAsset(createdAsset, 'en-US')
  const processedImage = await extension.space.waitUntilAssetProcessed(assetId, 'en-US')
  await extension.space.publishAsset(processedImage)

  return {
    sys: {
      type: 'Link',
      linkType: 'Asset',
      id: assetId,
    },
  }
}

export const setImage = async (extension, field, imageId) => {
  const value = extension.entry.fields[field].getValue()

  if (!imageId) {
    if (value !== undefined) {
      extension.entry.fields[field].setValue(undefined)
    }

    return
  }

  if (!value || value.sys.id !== getAssetId(field, imageId)) {
    const updatedValue = await getAssetValue(extension, field, imageId)
    extension.entry.fields[field].setValue(updatedValue)
  }
}

export const setCategoryTags = async (extension, tags) => {
  const currentTags = extension.entry.fields.categoryTags.getValue() || []

  // Apply sequentially for less likeliness to be rate limited
  const entries = []
  for (const tag of tags) {
    const nid = nidify(tag.name)
    const entry = await getOrCreateEntry(
      extension,
      'categoryTag',
      { 'fields.nid': nid },
      {
        fields: {
          nid: { 'en-US': nid },
          name: { 'en-US': tag.name },
        },
      }
    )
    entries.push(entry)
  }

  const currentEntries = (await extension.space.getEntries({
    content_type: 'categoryTag',
    'sys.id[in]': currentTags.map(tag => tag.sys.id).join(','),
  })).items

  const compareA = entries.map(e => e.sys.id).sort()
  const compareB = currentEntries.map(e => e.sys.id).sort()
  if (!_isEqual(compareA, compareB)) {
    await extension.entry.fields.categoryTags.setValue(
      entries.map(entry => ({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id,
        },
      }))
    )
  }
}
