import React, { useCallback, useEffect, useState } from 'react'
import { getClient } from '../../graphql/apolloClient'
import graphql from 'graphql-tag'
import Preview from './taglist-preview'
import { TextInput, HelpText, FormLabel, FieldGroup, Form } from '@contentful/forma-36-react-components'
import { TagListContainer } from './style'
import debounce from '../../debounce'

const GRAPHQL_URL = 'https://tv4se-graphql.a2d.tv/graphql'
const apolloClient = getClient(GRAPHQL_URL)
const VIDEO_ASSET_FOR_PLUGIN = `
query VideoAssetSearchForPlugin($nids: String, $tags: String) {
  videoAssetSearchForPlugin(nids: $nids, tags: $tags) {
    total_hits
    data {
      id
      image
      publishedDateTime
      title
    }
  }
}`

export const TagListV2 = () => {
  const [fields, setFields] = useState({ program: '', tags: '' })
  const [extension, setExtension] = useState(null)
  const [preview, setPreview] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(null)

  const runQuery = debounce(fields => {
    apolloClient
      .query({
        query: graphql(VIDEO_ASSET_FOR_PLUGIN),
        variables: { nids: fields.program, tags: fields.tags },
      })
      .then(result => setPreview(result.data))
      .catch(error => console.log('error', error))
      .finally(() => setLoadingPreview(false))
  }, 1000)

  const fetchPreview = useCallback(fields => {
    setLoadingPreview(true)
    runQuery(fields)
  }, [])

  useEffect(() => {
    window.contentfulExtension &&
      window.contentfulExtension.init(extension => {
        const values = extension.field.getValue() || {}
        extension.window.updateHeight(500)

        setExtension(extension)
        const newFields = {
          program: values.program || fields.program,
          tags: values.tags || fields.tags,
        }
        setFields(newFields)
        fetchPreview(newFields)
      })
  }, [])

  const onFieldChange = (field, value) => {
    const newFields = { ...fields, [field]: value }
    setFields(newFields)

    if (extension) {
      const { program, tags } = newFields
      extension.field.setValue({ program, tags }).catch(error => {
        console.log('Error: ' + JSON.stringify(error))
      })
    }

    fetchPreview(newFields)
  }

  return (
    <TagListContainer>
      <Form>
        <FieldGroup>
          <FormLabel htmlFor="program">Program</FormLabel>
          <TextInput
            id="program"
            name="program"
            width="large"
            value={fields.program}
            onChange={e => onFieldChange('program', e.target.value)}
          />
          <HelpText>Komma-separerad lista av program nid'ar</HelpText>
        </FieldGroup>
        <FieldGroup>
          <FormLabel htmlFor="tags">Taggar</FormLabel>
          <TextInput
            id="tags"
            name="tags"
            width="large"
            value={fields.tags}
            onChange={e => onFieldChange('tags', e.target.value)}
          />
          <HelpText>Komma-separerad lista av taggar</HelpText>
        </FieldGroup>
      </Form>

      <Preview preview={preview} loading={loadingPreview} />
    </TagListContainer>
  )
}

export default TagListV2
