import React, { useState, useRef, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getListStyle, getItemStyle, reorder } from '../../utils/multipleEntryUtils'
import useContentfulExtension from '../hooks/useContentfulExtension'
import update from 'immutability-helper'
import VideoAssetFinder from '../VideoAssetFinder'
import { createNewAssetFromFiles, createAsset } from './upload'
import NewsPost from './NewsPost'
import debounce from '../../debounce'
import { AddCircle } from 'styled-icons/material/AddCircle'
import { Flex } from 'rebass'
import { Trash } from 'styled-icons/boxicons-solid/Trash'
import ErrorBoundary from '../ErrorBoundary'
import { AddButton, NewsListContainer, DeleteEverything } from './style'
import { ApolloProvider } from 'react-apollo'
import apolloClient from '../../graphql/apolloClient'

const LIST_TYPES = {
  TOPNEWS: 'TOPNEWS',
  PRIO: 'PRIONEWS',
}

export default () => {
  const extension = useContentfulExtension()
  const videoAssetFinder = useRef(null)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [topNews, setTopNews] = useState([])
  const [prioNews, setPrioNews] = useState([])

  useEffect(() => {
    if (extension) {
      const deserialized = extension.field.getValue() || []
      setTopNews(deserialized.filter(listItem => listItem.isFeatured))
      setPrioNews(deserialized.filter(listItem => !listItem.isFeatured))
      extension.window.updateHeight(800)
    }
  }, [extension])

  const moveBetweenLists = (item, result) => {
    if (result.destination.droppableId === LIST_TYPES.TOPNEWS) {
      setPrioNews(prioNews.filter(np => np.id !== item.id))
      setTopNews(update(topNews.filter(pa => pa.id !== item.id), { $splice: [[result.destination.index, 0, item]] }))
    } else {
      setTopNews(topNews.filter(pa => pa.id !== item.id))
      setPrioNews(update(prioNews.filter(pa => pa.id !== item.id), { $splice: [[result.destination.index, 0, item]] }))
    }
  }

  const onRemove = (listType, id) => {
    const [list, setter] = listType === LIST_TYPES.TOPNEWS ? [topNews, setTopNews] : [prioNews, setPrioNews]
    const newsItem = list.find(newsItem => newsItem.id === id)
    if (newsItem && newsItem.images && newsItem.images.main16x9AssetId) {
      // disabled for now. open support  active ticket with contentful, whats wrong with unpublishAsset endpoint?
      // removeUploadedFiles(newsItem.images.main16x9AssetId)
    }

    setter(list.filter(pa => pa.id !== id))
  }

  const onVideoAssetSelected = (videoAsset, index) => {
    const newsPost = {
      id: videoAsset.id,
      title: videoAsset.title,
      pitch: videoAsset.description,
      images: {
        main16x9: videoAsset.image,
      },
      videoAsset,
    }
    setTopNews(update(topNews.filter(pa => pa.id !== newsPost.id), { $splice: [[topNews.length, 0, newsPost]] }))
  }
  const saveValueDebounced = debounce(serializedData => {
    extension.field.setValue(serializedData)
  }, 600)

  useEffect(() => {
    if (extension) {
      // only persist some fields. UI-specific fields are of no interest´
      const serializedData = [
        ...topNews.map(({ id, title, pitch, images, videoAsset }) => ({
          id,
          title,
          pitch,
          images,
          videoAsset,
          isFeatured: true,
        })),
        ...prioNews.map(({ id, title, pitch, images, videoAsset }) => ({
          id,
          title,
          pitch,
          images,
          videoAsset,
          isFeatured: false,
        })),
      ]
      saveValueDebounced(serializedData)
    }
  }, [prioNews, topNews])

  const updateFields = (listType, id, fieldValues) => {
    const targetList = listType === LIST_TYPES.TOPNEWS ? topNews : prioNews
    const setter = listType === LIST_TYPES.TOPNEWS ? setTopNews : setPrioNews
    const idx = targetList.findIndex(np => np.id === id)
    let nextStateValues = targetList[idx]
    Object.keys(fieldValues).forEach(key => {
      nextStateValues = {
        ...nextStateValues,
        [key]: fieldValues[key],
      }
    })
    setter(
      update(targetList, {
        [idx]: {
          $set: {
            ...nextStateValues,
          },
        },
      })
    )
  }

  const clearAll = () => {
    setPrioNews([])
    setTopNews([])
  }

  const handleUpload = async (id, fileUpload, listType) => {
    extension.notifier.success('bilden laddas upp')
    updateFields(listType, id, { isImageUploading: true })
    try {
      const locale = 'en-US'
      const { space } = extension
      const { base64Data, imageFile } = await createNewAssetFromFiles([...fileUpload.current.files])
      const upload = await space.createUpload(base64Data)
      const asset = await createAsset({ id, upload, file: imageFile, locale })
      const createdAsset = await space.createAsset(asset)
      await space.processAsset(createdAsset, locale)
      const processedAsset = await space.waitUntilAssetProcessed(createdAsset.sys.id, locale)
      const publishedAsset = await space.publishAsset(processedAsset)
      updateFields(listType, id, { isImageUploading: false })
      updateFields(listType, id, {
        images: {
          main16x9: `https:${publishedAsset.fields.file['en-US'].url}`,
          main16x9AssetId: `${publishedAsset.sys.id}`,
        },
      })
      extension.notifier.success('bilduppladdning lyckades')
    } catch (err) {
      extension.notifier.error('något gick fel vid uppladdning. Kontrollera filen och försök igen')
      updateFields(listType, id, { isImageUploading: false })
    }
  }

  // const removeUploadedFiles = async id => {
  //   const { space, notifier } = extension // properly initialized contentful extension.
  //   try {
  //     notifier.success('Removing uploaded image with id', id)
  //     const asset = await space.getAsset(id)
  //     if (asset) {
  //       // this code always fails with error 404 on unpublish
  //       const unpublishedAsset = await space.unpublishAsset(asset)
  //       // fails with 404
  //       await space.deleteAsset(unpublishedAsset)
  //       // also fails with 404.
  //       await space.deleteAsset(asset.sys.id)

  //       notifier.success('uploaded image deleted')
  //     }
  //   } catch (err) {
  //     notifier.error('borttagning misslyckades')
  //   }
  // }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    if (result.destination.droppableId !== result.source.droppableId) {
      const sourceList = result.destination.droppableId === LIST_TYPES.TOPNEWS ? prioNews : topNews
      const movedPost = sourceList[result.source.index]
      moveBetweenLists(movedPost, result)
      return
    }
    if (result.destination.droppableId === LIST_TYPES.PRIO) {
      const reorderedEntries = reorder(prioNews, result.source.index, result.destination.index)
      setPrioNews(reorderedEntries)
      return
    }
    if (result.destination.droppableId === LIST_TYPES.TOPNEWS) {
      const reorderedEntries = reorder(topNews, result.source.index, result.destination.index)
      setTopNews(reorderedEntries)
      return
    }
  }

  return (
    <ApolloProvider client={apolloClient}>
      <NewsListContainer>
        <DeleteEverything>
          <Trash
            onClick={() => {
              extension.dialogs
                .openConfirm({
                  title: 'Rensa innehåll?',
                  message: 'Vill du ta bort allt prioriterat innehåll?',
                  intent: 'negative',
                  confirmLabel: 'Rensa!',
                  cancelLabel: 'Avbryt',
                })
                .then(result => {
                  if (result) {
                    clearAll()
                  }
                })
            }}
          />
        </DeleteEverything>
        <ErrorBoundary>
          <Flex width={1} align-items="center">
            <h2 style={{ width: '80%', display: 'inline-block' }}>Toppnyheter</h2>
            <AddButton
              onClick={() => {
                setShowAddDialog(true)
              }}
            >
              <AddCircle />
            </AddButton>
          </Flex>
          {showAddDialog && (
            <div style={{ marginBottom: 16 }}>
              <VideoAssetFinder
                ref={videoAssetFinder}
                parentExtension={extension}
                onCloseResults={() => setShowAddDialog(false)}
                onVideoAssetSelected={videoAsset => {
                  onVideoAssetSelected(videoAsset, prioNews)
                  setShowAddDialog(false)
                }}
                smallLayout
              />
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={LIST_TYPES.TOPNEWS}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={{ ...getListStyle(snapshot.isDraggingOver), minHeight: 100 }}>
                  {topNews.map((newsPost, index) => (
                    <Draggable key={newsPost.id} draggableId={newsPost.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <NewsPost
                            extension={extension}
                            newsPost={newsPost}
                            onFieldChange={fieldValues => {
                              updateFields(LIST_TYPES.TOPNEWS, newsPost.id, fieldValues)
                            }}
                            onRemove={() => {
                              onRemove(LIST_TYPES.TOPNEWS, newsPost.id)
                            }}
                            handleUpload={(id, fileUpload) => handleUpload(id, fileUpload, LIST_TYPES.TOPNEWS)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <h2>Prioriterade nyheter</h2>
            <Droppable droppableId={LIST_TYPES.PRIO}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={{ ...getListStyle(snapshot.isDraggingOver), minHeight: 100 }}>
                  {prioNews.map((newsPost, index) => (
                    <Draggable key={newsPost.id} draggableId={newsPost.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <NewsPost
                            extension={extension}
                            newsPost={newsPost}
                            onFieldChange={fieldValues => {
                              updateFields(LIST_TYPES.PRIO, newsPost.id, fieldValues)
                            }}
                            onRemove={() => {
                              onRemove(LIST_TYPES.PRIO, newsPost.id)
                            }}
                            handleUpload={(id, fileUpload) => handleUpload(id, fileUpload, LIST_TYPES.PRIO)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ErrorBoundary>
      </NewsListContainer>
    </ApolloProvider>
  )
}
