export const createNewAssetFromFiles = async files => {
  // Filter only images
  const imageFiles = files.filter(file => /^image\/[\w-_]+$/.test(file.type))

  // If no images were found, raise an error
  if (imageFiles.length === 0) {
    throw new Error('Only images are allowed')
  }

  // Only one image at a time is supported. In the future, we can accept set of images per locale ?
  if (imageFiles.length > 1) {
    throw new Error('Please drop only one image at a time')
  }

  const imageFile = imageFiles[0]

  // Encode the file as Base64, so we can pass it through SDK proxy to get it uploaded
  const [base64Prefix, base64Data] = await readFileAsUrl(imageFile)
  return {
    base64Data,
    base64Prefix,
    imageFile,
  }
}

export function readFileAsUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function(e) {
      resolve(e.target.result.split(','))
    }

    reader.onerror = function(e) {
      reject(e.error)
    }

    reader.onabort = function(e) {
      reject(new Error('File aborted.'))
    }

    reader.readAsDataURL(file)
  })
}

export const createAsset = ({ id, upload, file, locale }) => {
  const asset = {
    fields: {
      title: {},
      description: {},
      file: {},
    },
  }
  asset.fields.title[locale] = `newspost_image_asset_${id}`
  asset.fields.description[
    locale
  ] = `Image uploaded to newstlist for id ${id} - please dont reuse this anywhere else as it will get automatically removed`
  asset.fields.file[locale] = {
    contentType: file.type,
    fileName: `newspost_image_${id}`,
    uploadFrom: {
      sys: {
        type: 'Link',
        linkType: 'Upload',
        id: upload.sys.id,
      },
    },
  }

  return asset
}
