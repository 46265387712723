import { isWithinRange, isBefore, isAfter } from 'date-fns'
export const STATUS = {
  SCHEDULED: 'SCHEDULED',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
}

export function schedulingStatus(from, to) {
  const now = new Date()
  if (isBefore(now, from)) {
    return STATUS.SCHEDULED
  }
  if (to && isAfter(now, to)) {
    return STATUS.EXPIRED
  } else if (to && isWithinRange(now, from, to)) {
    return STATUS.ACTIVE
  }

  return STATUS.ACTIVE
}

export const STATUS_DATA = {
  ACTIVE: {
    text: 'AKTIV',
    color: '#4caf50',
  },
  EXPIRED: {
    text: 'INAKTIV PGA SCHEMALÄGGNING',
    color: '#f44336',
  },
  SCHEDULED: {
    text: 'SCHEMALAGD',
    color: '#ffeb3b',
  },
}
