import React, { useState, useEffect } from 'react'
import { Tablet } from 'styled-icons/material/Tablet'
import { Smartphone } from 'styled-icons/material/Smartphone'
import { LaptopChromebook as Web } from 'styled-icons/material/LaptopChromebook'
import { Tv as Bigscreen } from 'styled-icons/material/Tv'
import { SelectMultiple as All } from 'styled-icons/boxicons-regular/SelectMultiple'
import useContentfulExtension from '../hooks/useContentfulExtension'
import { IconWrapper } from './style'
import { HelpText } from '@contentful/forma-36-react-components'

const shouldUpdate = (oldState, newState) => {
  if (!oldState) {
    return true
  }
  return (
    oldState.mobile !== newState.mobile ||
    oldState.tablet !== newState.tablet ||
    oldState.web !== newState.web ||
    oldState.bigscreen !== newState.bigscreen
  )
}

export default ({ onPlatformsChanged = () => {} }) => {
  const [initialized, setInitialized] = useState(false)
  const [mobile, setMobile] = useState(false)
  const [tablet, setTablet] = useState(false)
  const [web, setWeb] = useState(false)
  const [bigscreen, setBigscreen] = useState(false)
  const extension = useContentfulExtension()

  useEffect(
    () => {
      if (extension) {
        const values = extension.field.getValue()
        if (values) {
          setMobile(values.mobile)
          setTablet(values.tablet)
          setWeb(values.web)
          setBigscreen(values.bigscreen)
        } else {
          extension.field.setValue({
            mobile: true,
            tablet: true,
            web: true,
            bigscreen: true,
          })
          setMobile(true)
          setTablet(true)
          setWeb(true)
          setBigscreen(true)
        }
      }
      setInitialized(true)
    },
    [extension]
  )

  useEffect(
    () => {
      if (!initialized) {
        return
      }
      const value = extension.field.getValue()

      // invalid if not all fields are set.
      extension.field.setInvalid(Object.keys(value || {}).length < 4)
      onPlatformsChanged({
        mobile,
        tablet,
        web,
        bigscreen,
      })
      // quick-fix: only edit value in contentful if current state is different from saved state.
      if (
        extension &&
        shouldUpdate(value, {
          mobile,
          tablet,
          web,
          bigscreen,
        })
      ) {
        extension.field.setValue({ mobile, tablet, web, bigscreen })
      }
    },
    [mobile, tablet, web, bigscreen]
  )
  const isEverythingSelected = mobile && tablet && web && bigscreen
  return (
    <>
      <IconWrapper title="Mobile" active={mobile}>
        <Smartphone />
        <span>Mobile</span>
        <input type="checkbox" checked={mobile} onChange={e => { setMobile(!mobile); setTablet(!mobile) }} />
      </IconWrapper>
      <IconWrapper title="Tablet" active={tablet}>
        <Tablet />
        <span>Tablet</span>
        <input type="checkbox" checked={tablet} disabled onChange={e => setTablet(!tablet)} />
      </IconWrapper>
      <IconWrapper title="Web" active={web}>
        <Web />
        <span>Web</span>
        <input type="checkbox" checked={web} onChange={e => setWeb(!web)} />
      </IconWrapper>
      <IconWrapper title="Big screen" active={bigscreen}>
        <Bigscreen />
        <span>Bigscreen</span>
        <input value={bigscreen} type="checkbox" checked={bigscreen} onChange={e => setBigscreen(!bigscreen)} />
      </IconWrapper>
      <IconWrapper title="Select all" active={isEverythingSelected}>
        <All />
        <span>All</span>
        <input
          checked={isEverythingSelected}
          type="checkbox"
          onChange={e => {
            if (!isEverythingSelected) {
              setMobile(true)
              setTablet(true)
              setWeb(true)
              setBigscreen(true)
            } else {
              setMobile(false)
              setTablet(false)
              setWeb(false)
              setBigscreen(false)
            }
          }}
        />
      </IconWrapper>
      <div style={{ margin: '9px 0' }}>
        <HelpText>Currently, all content on the mobile and tablet apps are the same. Therefore, the tablet option is disabled and controlled by the mobile checkbox.</HelpText>
      </div>
    </>
  )
}
