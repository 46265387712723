import styled from 'styled-components'

export const IconWrapper = styled.label`
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  align-items: flex-start;
  justify-items: stretch;
  justify-content: center;
  margin-left: 4px;
  max-width: 44px;
  flex-wrap: wrap;
  span {
    text-align: center;
    font-size: 10px;
    display: block;
    width: 100%;
  }
  svg {
    opacity: ${props => (!props.active ? '0.5' : '1')};
    display: block;
    width: 44px;
    height: 44px;
  }
  input {
    transform: scale(1.5);
    padding: 10px;
    display: inline-block;
  }
`
