import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Spinner } from 'styled-icons/fa-solid/Spinner'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Loader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  ${Spinner} {
    max-width: 20px;
    animation: ${rotate360} 1s infinite linear;
  }
`

const LoaderOverlayStyled = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: wait;
  display: flex;
  justify-content: center;
  padding-top: 15%;
  ${Loader} {
    width: 50px;
    ${Spinner} {
      color: #fff;
      fill: #fff;
      max-height: 50px;
      max-width: 50px;
    }
  }
`

export const LoaderOverlay = () => (
  <LoaderOverlayStyled>
    <Loader>
      <Spinner />
    </Loader>
  </LoaderOverlayStyled>
)
export default () => (
  <Loader>
    <Spinner />
  </Loader>
)
