import { useEffect, useState } from 'react'

export default () => {
  const [extension, setExtension] = useState()
  useEffect(() => {
    window.contentfulExtension &&
      window.contentfulExtension.init(extension => {
        setExtension(extension)
      })
  })
  return extension
}
