import 'react-keyed-file-browser/dist/react-keyed-file-browser.css'
import React, { useRef, useState, useEffect } from 'react'
import aws from 'aws-sdk'
import useContentfulExtension from '../hooks/useContentfulExtension'
import _get from 'lodash.get'
import _deburr from 'lodash.deburr'
import { FileBlank } from 'styled-icons/boxicons-solid/FileBlank'
import { Folder } from 'styled-icons/boxicons-regular/Folder'
import { FolderOpen } from 'styled-icons/boxicons-regular/FolderOpen'
import { FileFind } from 'styled-icons/boxicons-regular/FileFind'
import { Upload } from 'styled-icons/boxicons-regular/Upload'
import Loader from '../styled/Loader'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import FileBrowser from 'react-keyed-file-browser'
const BUCKET_INBOX = 'a2dguitrailers-general-inbox-prod'
const BUCKET_ORIGIN = 'a2dguitrailers-general-origin-prod'

const Button = styled.button`
  padding: 8px 16px;
  margin: 8px 0;
  display: block;
`

export default () => {
  const extension = useContentfulExtension()
  const fileUpload = useRef(null)
  const [files, setFiles] = useState([])
  const [s3, setS3] = useState(null)
  const [url, setURL] = useState(null)
  const [bucketFolder, setBucketFolder] = useState(null)
  // const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (extension) {
      extension.window.startAutoResizer()
      const accessKeyId = _get(extension, 'parameters.installation.accessToken')
      const secretAccessKey = _get(extension, 'parameters.installation.accessSecret')
      const folder = _get(extension, 'parameters.installation.folder') || 'tv4contentful/'

      aws.config.update({
        region: 'us-east-1',
        accessKeyId,
        secretAccessKey,
      })
      const values = extension.field.getValue() || {}
      const { url, loading } = values
      setLoading(loading)
      setURL(url)
      const s3 = new aws.S3()
      s3.listObjects({ Prefix: folder.replace('/', ''), Bucket: BUCKET_ORIGIN }, (error, data) => {
        if (error) {
          console.log(error)
        }
        setFiles(
          data.Contents.filter(f => f.Key.endsWith('.mp4') && !f.Key.startsWith('comet/')).map(f => ({
            key: f.Key,
            modified: f.LastModified,
            size: f.Size,
          }))
        )
      })

      setBucketFolder(folder)
      setS3(s3)
    }
  }, [extension])

  useEffect(() => {
    const interval = setInterval(() => {
      if (loading) {
        s3.listObjects({  Prefix: bucketFolder.replace('/', ''), Bucket: BUCKET_ORIGIN }, (error, data) => {
          if (error) {
            return null
          }
          if (data.Contents.some(item => item.Key === `${url}`)) {
            extension.field.setInvalid(false)
            extension.field.setValue({
              url: `${url}`,
              loading: false,
            })
            setLoading(false)
          }
        })
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [loading])

  const handleUpload = () => {
    const url = _deburr(fileUpload.current.files[0].name.replace(/\+|\s+|%20/g, ''))
    extension.field.setInvalid(true)
    extension.field.setValue({
      url: `${bucketFolder}${url}`,
      loading: true,
    })
    setLoading(true)

    setURL(`${bucketFolder}${url}`)
    if (s3) {
      s3.putObject(
        {
          Bucket: `${BUCKET_INBOX}`,
          Body: fileUpload.current.files[0],
          Key: `${bucketFolder}${url}`,
        },
        function(error, data) {
          if (error) {
            return null
          }
        }
      )
    }
  }
  const handleFileSelect = ({ key }) => {
    if (extension) {
      extension.field.setValue({
        url: key,
        loading: false,
      })
      setURL(key)
    }
  }
  return (
    <div style={{ minHeight: '500px' }}>
      {/* {!url && <Select value={null} onChange={val => handleSelect(val)} options={content} />} */}
      <div style={{ minHeight: 140, border: '1px solid #d2d2d2', padding: 16 }}>
        {!loading && !url && (
          <Flex style={{ fontsize: 18 }}>
            <FileFind width={18} height={18} />
            Select uploaded trailer
          </Flex>
        )}
        {!loading && url && (
          <>
            <video controls style={{ width: '100%', height: '100%' }}>
              {/*  Add prod CDN */}
              <source src={`https://trailer-cdn.a2d.tv/${url} `} type="video/mp4" />
            </video>
            <Button
              onClick={() => {
                setURL(null)
                extension.field.setValue({
                  loading: false,
                  url: null,
                })
              }}
            >
              Rensa
            </Button>
          </>
        )}
        {loading && (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap" flexDirection="column" width={1}>
            <span>
              <b>uploading</b> "{url}"
            </span>
            <Loader style={{ margin: '0 10px 32px 5px' }} />
            <Button
              onClick={() => {
                extension.field.setValue({
                  loading: false,
                  url: null,
                })
                setURL(null)
                setLoading(false)
              }}
            >
              Avbryt
            </Button>
          </Flex>
        )}

        {!url && files && files.length > 0 && (
          <Box>
            <Box mb={4} style={{ borderBottom: '1px solid black' }}>
              <FileBrowser
                icons={{
                  File: <FileBlank width={18} height={18} />,
                  Folder: <Folder width={18} height={18} />,
                  FolderOpen: <FolderOpen width={18} height={18} />,
                }}
                files={files}
                onSelectFile={f => handleFileSelect(f)}
                isOpen={true}
              />
            </Box>
            <Flex alignItems="flex-start" flexWrap="wrap">
              <Box width={1}>
                <h3 style={{ fontSize: 18 }}>
                  <Upload width={18} height={18} /> Upload new trailer{' '}
                </h3>
              </Box>
              <Box width={1 / 3}>
                <label>Upload to </label>
                <select value={bucketFolder} onChange={e => setBucketFolder(e.target.value)}>
                  <option value="tv4contentful/">tv4contentful/</option>
                </select>
              </Box>
              <Box width={1 / 2}>
                <input onChange={handleUpload} ref={fileUpload} type="file" />
              </Box>
            </Flex>
          </Box>
        )}
      </div>
    </div>
  )
}
