import React from 'react'
import { Box, Flex } from 'rebass'
import { format } from 'date-fns'
import { Date, PitchLabel } from './style'
import { getPublishStatus, CONTENTFUL_STATUS } from '../../modules/contentful-status'
import PublishStatusLabel from '../styled/PublishStatusLabel'

export default ({ card, displayName }) => {
  const { internalName, publishFrom, publishTo, isPitchCard, schedulingStatus } = card
  const contentfulPublishStatus = getPublishStatus(card)
  return (
    <Flex>
      <Box width={1 / 3}>
        <Flex alignItems="center">
          {(contentfulPublishStatus === CONTENTFUL_STATUS.PUBLISHED ||
            contentfulPublishStatus === CONTENTFUL_STATUS.UPDATED) && <PublishStatusLabel status={schedulingStatus} />}
          <h3 style={{ margin: '0 0 4px 0', display: 'inline' }}> {internalName} </h3>
        </Flex>
        {displayName} {isPitchCard && <PitchLabel>PITCH CARD</PitchLabel>}
      </Box>
      <Box width={1 / 3} pt={1}>
        <Date>Från</Date>
        {publishFrom && format(publishFrom, 'YYYY-MM-DD HH:mm')}
        <br />
        <Date>Till</Date>
        {publishTo && format(publishTo, 'YYYY-MM-DD HH:mm')}
      </Box>
    </Flex>
  )
}
