import gql from 'graphql-tag'
export const VIDEO_ASSET_QUERY = gql`
  query videoAssets($id: String!) {
    videoAsset(id: $id) {
      id
      image
      title
      description
      duration
      live
      broadcastDateTime
      humanDuration
      publishedDateTime
    }
  }
`
