import styled from 'styled-components'
import { Col } from './index'
export const Title = styled.div`
  max-width: 95%;
  word-break: break-word;
  display: inline-block;
  white-space: pre-wrap;
  margin-bottom: auto;
`
export const Thumbnail = styled.img`
  max-width: 100%;
`
export const CloseButton = styled.div`
  position: sticky;
  right: 60px;
  cursor: pointer;
  z-index: 2;
  color: #fff;
  background-color: red;
  padding: 3px;
  border-radius: 5px;
  float: right;
  top: 20px;
  margin-top: 10px;
`
export const ResultsGrid = styled.div`
  display: flex;
  max-height: 500px;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
  box-shadow: 2px 6px 4px #d3d3d3;
  background-color: #fff;
  overflow: scroll;
  > ${Col} {
    border: 1px solid #d3d3d3;
    margin-bottom: 5px;
    cursor: pointer;
    flex-basis: 19%;
    margin-right: 5px;
    &:hover {
      background-color: #d3d3d3;
    }
  }
`
export const ResultsWrapper = styled.div`
  position: relative;
  margin-left: 10px;
`
export const LoadMore = styled.button`
  width: 100%;
  height: 40px;
  background-color: red;
  color: #fff;
  font-weight: bold;
  font-size: 16px;

  &:active,
  &:focus {
    outline: none;
  }
`
export const Preview = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin-bottom: 10px;
`
export const Flex = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background-color: #fff;
  margin-top: 10px;
`

export const VideoAssetWrapper = styled.div`
  padding: 5px 10px;
`
export const Upcoming = styled.span`
  background: #f79b0c;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  padding: 4px;
`
