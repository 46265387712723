import React, { useState, useEffect } from 'react'
import useContentfulExtension from '../hooks/useContentfulExtension'
import Select from 'react-select'
import styled from 'styled-components'

const PageSelectorHeader = styled.div`
  margin-top: 16px;
`
const PageLink = styled.a`
  margin-top: 16px;
  display: block;
  font-size: 18px;
`

export default ({ onPageSelected, initialSelectedValue, isStandAlone = false }) => {
  const extension = useContentfulExtension()
  const [isInitialized, setIsInitialized] = useState(false)
  const [selected, setSelected] = useState(null)
  const [pageOptions, setPageOptions] = useState([])

  useEffect(() => {
    if (extension) {
      extension.space
        .getPublishedEntries({
          content_type: 'page',
          include: 2,
        })
        .then(entries => {
          const opts = entries.items
            .filter(p => p.fields)
            .map(page => {
              return {
                label: page.fields.title['en-US'],
                value: page.fields.id['en-US'],
                sysId: page.sys.id,
              }
            })
          setPageOptions(opts)
          if (initialSelectedValue) {
            const preselected = opts.find(opt => opt.value === initialSelectedValue)
            if (preselected) {
              setSelected(preselected)
            }
          }
          setIsInitialized(true)
        })
    }
  }, [extension])

  useEffect(() => {
    if (selected) {
      onPageSelected(selected.value)
    }
  }, [selected])

  return (
    <>
      <PageSelectorHeader />
      <Select isLoading={!isInitialized} options={pageOptions} onChange={opt => setSelected(opt)} value={selected} />
      {selected && (
        <PageLink
          href="/"
          onClick={e => {
            e.preventDefault()
            extension.navigator.openEntry(selected.sysId, { slideIn: true })
          }}
        >
          {`View page: "${selected.label}"`}
        </PageLink>
      )}
    </>
  )
}
