import React from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Date, PitchLabel } from './style'
import { getPublishStatus, CONTENTFUL_STATUS } from '../../modules/contentful-status'
import PublishStatusLabel from '../styled/PublishStatusLabel'
import { STATUS_DATA } from '../../utils/SchedulingStatus'

const PreviewImage = styled.img`
  min-width: 68px;
  max-width: 68px;
`
const CardPreviewImageContainer = styled(Box)`
  margin: 0 4px;
  padding-top: 16px;
  justify-content: flex-start;
  > span {
    font-weight: 600;
    display: block;
  }
`
const EmptyImage = styled.div`
  opacity: 0.5;
  margin-top: 4px;
  img {
    height: 28px;
    display: block;
    margin: auto;
    width: 50%;
  }
`

const CardType = styled.div`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  h3 {
    display: block;
    margin-bottom: 4px;
    margin-right: 8px;
    text-decoration: underline;
  }
  span {
    display: block;
    margin-bottom: 4px;
  }
`

const emptyImage = (
  <EmptyImage>
    <img src="/x.svg" alt="empty" />
  </EmptyImage>
)

export default ({ card }) => {
  const contentfulPublishStatus = getPublishStatus(card)
  const {
    schedulingStatus,
    publishFrom,
    publishTo,
    isPitchCard,
    internalName,
    main16x9,
    main16x7,
    main4x3,
    main1x1,
    title,
  } = card
  return (
    <Flex width={1} flexWrap="wrap">
      {(contentfulPublishStatus === CONTENTFUL_STATUS.PUBLISHED ||
        contentfulPublishStatus === CONTENTFUL_STATUS.UPDATED) && (
        <Flex alignItems="center" width={1}>
          <PublishStatusLabel status={schedulingStatus} />
          {STATUS_DATA[schedulingStatus].text}
          {contentfulPublishStatus === CONTENTFUL_STATUS.UPDATED && (
            <div
              style={{
                marginLeft: 4,
                fontSize: 12,
                color: contentfulPublishStatus.color,
              }}
            >
              {' '}
              (unpublished)
            </div>
          )}
        </Flex>
      )}
      <Flex width={1 / 3} flexWrap="wrap">
        <CardType>
          {internalName && <h3> {internalName} </h3>}
          {title && <span>{title}</span>}
          {isPitchCard && <PitchLabel>PITCH CARD</PitchLabel>}
        </CardType>

        <Box width={1}>
          <Date>Från</Date>
          {publishFrom && format(publishFrom, 'YYYY-MM-DD HH:mm')}
        </Box>
        <Box width={1}>
          <Date>Till</Date>
          {publishTo && format(publishTo, 'YYYY-MM-DD HH:mm')}
        </Box>
      </Flex>
      <Flex alignItems="flex-start" flexWrap="wrap" justifyContent="flex-start">
        <CardPreviewImageContainer>
          <span>main16x9</span>
          {main16x9 ? (
            <a href={main16x9} target="_new">
              <PreviewImage alt="preview" src={main16x9} />
            </a>
          ) : (
            emptyImage
          )}
        </CardPreviewImageContainer>
        <CardPreviewImageContainer>
          <span>main16x7</span>
          {main16x7 ? (
            <a href={main16x9} target="_new">
              <PreviewImage alt="preview" src={main16x7} />
            </a>
          ) : (
            emptyImage
          )}
        </CardPreviewImageContainer>
        <CardPreviewImageContainer>
          <span>main4x3</span>
          {main4x3 ? (
            <a href={main16x9} target="_new">
              <PreviewImage alt="preview" src={main4x3} />
            </a>
          ) : (
            emptyImage
          )}
        </CardPreviewImageContainer>
        <CardPreviewImageContainer>
          <span>main1x1</span>
          {main1x1 ? (
            <a href={main16x9} target="_new">
              <PreviewImage alt="preview" src={main1x1} />
            </a>
          ) : (
            emptyImage
          )}
        </CardPreviewImageContainer>
      </Flex>
    </Flex>
  )
}
