import React from 'react'
import styled from 'styled-components'
import { Preview, Thumbnail, Upcoming } from '../styled/ResultsGrid'
import { Button } from '@contentful/forma-36-react-components'
const VideoAssetWrapper = styled.div`
  padding: 5px 10px;
`
const Content = styled.div`
  width: 200px;
  padding-left: 12px;
  margin: auto 0;
  p {
    margin: 0 0 8px;
  }
`

export default ({ selected, isUpcoming, reset }) => {
  return (
    <VideoAssetWrapper>
      <p> Valt asset-id: {selected ? selected.id : 'Inget än'}</p>
      {selected && (
        <>
          <Preview>
            <Thumbnail src={selected.image} style={{ maxHeight: 82 }} />
            <Content>
              <p>{selected.title}</p>
              {isUpcoming && <Upcoming>Kommande</Upcoming>}
            </Content>
      
          </Preview>
          <Button size="small" buttonType="muted" onClick={reset}>
            Ta bort asset-id
          </Button>
        </>
      )}
    </VideoAssetWrapper>
  )
}
