import React, { useEffect, useState } from 'react'
import { HelpText, SelectField, Option } from '@contentful/forma-36-react-components'
import useContentfulExtension from '../hooks/useContentfulExtension'
import _get from 'lodash.get'
import _capitalize from 'lodash.capitalize'
import _startcase from 'lodash.startcase'

export default () => {
  const extension = useContentfulExtension()
  const [value, setValue] = useState(undefined)
  const [options, setOptions] = useState([])
  const [helpText, setHelpText] = useState()

  useEffect(() => {
    if (extension) {
      const options = (_get(extension, 'field.validations') || []).find(v => !!v.in).in || []
      setOptions(options)
      setValue(extension.field.getValue() || extension.parameters.instance.defaultValue)
      setHelpText(extension.parameters.instance.helpText)
      extension.window.startAutoResizer()
    }
    return () => {
      if (extension) {
        extension.window.stopAutoResizer()
      }
    }
  }, [extension])

  useEffect(() => {
    if (extension && value) {
      extension.field.setValue(value)
    }
  }, [extension, value])

  if (value === undefined || !extension) {
    return null
  }

  return (
    <div>
      <div style={{ margin: '9px 0' }}>
        <SelectField
          value={value}
          onChange={e => {
            setValue(e.target.value)
          }}
        >
          {options.map(o => (
            <Option key={o} value={o}>
              {_capitalize(_startcase(o))}
            </Option>
          ))}
        </SelectField>
      </div>
      {helpText && (
        <div style={{ margin: '9px 0' }}>
          <HelpText>{helpText}</HelpText>
        </div>
      )}
    </div>
  )
}
