import React from 'react'
import styled from 'styled-components'
import {Flex, Box } from 'rebass'
const Preview = styled(Flex)`
    strong {
        display: block;
        margin: 4px 0;
    }
    img {
        max-width: 100%;
        max-height: 150px;
    }
`
interface PreviewProps {
    images:  {
        main4x3?: string,
        main16x9?: string,
        main16x7?: string,
        main16x9Annotated?: string,
        main2x3Annotated?:string
    }
}

export default ({images}: PreviewProps) => {
return <Preview mx={-1} width={1}>
{images.main4x3 && <Box px={1}width={1/5}> <strong>main4x3 </strong><a target="_blank" href={images.main4x3}><img src={images.main4x3} /></a></Box>}
{images.main16x9 &&<Box px={1}width={1/5}> <strong>main16x9</strong><a target="_blank" href={images.main16x9}> <img src={images.main16x9} /></a></Box>}
{images.main16x7 &&<Box px={1}width={1/5}> <strong>main16x7</strong><a target="_blank" href={images.main16x7}> <img src={images.main16x7} /></a></Box>}
{images.main2x3Annotated &&<Box px={1}width={1/5}> <strong>main2x3Annotated</strong> <a target="_blank" href={images.main2x3Annotated}><img src={images.main2x3Annotated} /></a></Box>}
{images.main16x9Annotated &&<Box px={1}width={1/5}> <strong>main16x9Annotated</strong> <a target="_blank" href={images.main16x9Annotated}><img src={images.main16x9Annotated} /></a></Box>}
</Preview>
}