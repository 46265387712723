import React from 'react'
import { Upload } from 'styled-icons/boxicons-regular/Upload'
import { Cross } from 'styled-icons/icomoon/Cross'
import { UploaderOverlay } from './style'
import { Box } from 'rebass'
export default ({
  image,
  fileUpload,
  id,
  videoAsset,
  title,
  isImageUploading,
  handleUpload,
  onFieldChange,
  images,
}) => {
  return (
    <UploaderOverlay
      onClick={() => {
        if (fileUpload && fileUpload.current) {
          fileUpload.current.click()
        }
      }}
    >
      {!isImageUploading && (
        <img src={image} alt={title} style={{ display: 'inline-block', maxWidth: '100%', maxHeight: 100 }} />
      )}
      {image === videoAsset.image ? (
        <>
          <Upload />
          <input
            style={{ display: 'none' }}
            onChange={() => {
              handleUpload(id, fileUpload)
            }}
            ref={fileUpload}
            accept="image/*"
            type="file"
          />
          <span>Ladda upp och ersätt bild</span>
        </>
      ) : (
        <Box width={1}>
          <Cross />
          <button
            onClick={e => {
              onFieldChange({
                images: {
                  ...images,
                  main16x9: videoAsset.image,
                },
              })
            }}
          />
          <span>Återgå till orginalbild</span>
        </Box>
      )}
    </UploaderOverlay>
  )
}
