import styled from 'styled-components'

export const ErrorMessage = styled.div`
  background-color: 'red';
  color: 'white';
`

export const ImageWrap = styled.div`
  width: 100px;
`

export const MetaData = styled.div`
  margin-left: 20px;
  width: 80%;
`

export const MetaDataWrapper = styled.div`
  display: flex;

  > h3 {
    width: 60%;
  }
`

export const Overlay = styled.div`
  opacity: ${props => (props.loading === true ? 0.3 : 1)}
  -webkit-transition: opacity 0.3 ease-in-out;
  -moz-transition: opacity 0.3 ease-in-out;
  transition: opacity 0.3 ease-in-out;
`

export const TimeStamp = styled.div`
  font-size: 10px;
  min-width: 100px;
`
export const AssetId = styled.div`
  display: inline-block;
  font-size: 10px;
  margin-left: 10px;

  > span {
    font-weight: 600;
  }
  > svg {
    margin-bottom: 3px;
  }
`

export const VideoListItem = styled.div`
  display: flex;
  font-size: 12px;
  flex-wrap: wrap;
  align-items: top;
  position: relative;
  h3 {
    margin: 0 50px 4px 0;
    display: inline-block;
  }
  > span {
    font-size: 10px;
  }
  p,
  h3 {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }
  button {
    cursor: pointer;
    color: red;
    border: 0;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
  }
`
