import styled from 'styled-components'
import { STATUS_DATA } from '../../utils/SchedulingStatus'
export default styled.div`
  padding: 4px;
  min-width: 22px;
  margin-right: 8px;
  margin-bottom: 4px;
  min-height: 22px;
  border-radius: 22spx;
  display: inline-block;
  color: #fff;
  background-color: ${props => STATUS_DATA[props.status].color};
`
