import React from 'react'
import { imageProxyUrl } from '../../modules/image-proxy'
import { PreviewList, PreviewText } from './style'
import { LoaderOverlay } from '../styled/Loader'
export default ({ preview, loading }) => {
  if (preview && preview.videoAssetSearch && preview.videoAssetSearch.totalHits > 0) {
    const { totalHits, videoAssets } = preview.videoAssetSearch
    return (
      <div>
        {loading && <LoaderOverlay />}
        <PreviewText>This query will return {totalHits} assets </PreviewText>
        <PreviewText>Preview - First 8 Hits</PreviewText>
        <PreviewList>
          {videoAssets &&
            videoAssets.slice(0, 8).map((v, i) => (
              <div key={i}>
                <img src={imageProxyUrl(v.image, 150)} alt={v.title} />
                <h3> {v.title} </h3>
              </div>
            ))}
        </PreviewList>
      </div>
    )
  }
  return null
}
