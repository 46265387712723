import React, {useState, useEffect} from 'react'
import { TextInput, Button } from '@contentful/forma-36-react-components'
import Select from 'react-select'
import { LabelRow }  from './types'
import DateRange from './DateRange'
import { Flex, Box } from 'rebass'
// series?source=https://img.cmore.incomet.io/4081b995-70fb-483c-aa0b-a8af17ee704b/crop16x9.jpg&text=ONSDAGAR
const proxyUrl = `https://imageproxy.a2d.tv/annotated/series`

interface LabelRowProps {
 labelRow: LabelRow,
 programs: any[],
 onChange: ((labelRow: LabelRow) => void)
 onDuplicate?: ((labelRow: LabelRow) => void)
 onRemove: (() => void)
}

export default ({ onChange, onDuplicate, labelRow , onRemove, programs}: LabelRowProps) => {
const [selectedOptions, setSelectedOptions] = useState<any>([])
const [label, setLabel] = useState<string>(labelRow.label || '')
const [cdpText, setCdpText] = useState<string>(labelRow.cdpText || '')
const [from, setFrom] = useState<Date>()
const [to, setTo] = useState<Date>()
const [showPreview, setShowPreview] = useState(false)
const parseForm = () => {
    return {
        id: labelRow.id,
        from, 
        to, 
        label,
        cdpText,
        programs: selectedOptions || []}
}

useEffect(() => {
    onChange(parseForm())
}, [labelRow.id, label,from,to,selectedOptions])

const optionLabels = programs.map(p => ({
    value: p.nid,
    label: p.nid,
}))

return <>
        <Flex width={1} flexWrap="wrap" justifyContent="space-between" style={{minHeight: 400}}>
        <Box width={1/5}>
        <div>Program</div> 
        <Select
            isMulti
            value={selectedOptions}
            hideSelectedOptions
            onChange={(values: any) => {
                setSelectedOptions(values)
            }}
            options={optionLabels}
            style={{ width: '100%' }}
            />
        </Box>
        <Box width={2/5}>
        <div>Text on CDP</div> 
              <TextInput
                style={{textTransform: 'uppercase'}}
                name="label"
                type="text"
                onChange={(e) => {
                    setCdpText(e.target.value)
                }}
                value={labelRow.cdpText}
                className="f36-margin-bottom--m"
            />
         <div>Label</div> 
            <TextInput
                style={{textTransform: 'uppercase'}}
                name="info"
                type="text"
                onChange={(e) => {
                    setLabel(e.target.value.toUpperCase())
                }}
                value={labelRow.label}
                className="f36-margin-bottom--m"
            />
           
            <Button disabled={!selectedOptions || label.length < 1} style={{marginTop: 4}} onClick={() => setShowPreview(!showPreview)}> {showPreview === true ? "Hide Preview" : 'Preview images'} </Button>
        </Box>  
        <Box width={1/5} px={2}>
            <div> Active dates</div>
            <DateRange fromValue={labelRow.from} toValue={labelRow.to} onChange={(from, to) => {
                if (from) {
                    setFrom(from)
                }
                if (to) {
                    setTo(to)
                }
            }}/>
        </Box>
        <Flex width={1/5}>
            <Button buttonType="negative" onClick={onRemove}> Remove </Button>
            {/* <Button buttonType="positive" onClick={() => onDuplicate(parseForm())}> Duplicate </Button> */}
        </Flex>
    </Flex>
    <Flex width={1} flexWrap="wrap" mt={2} mb={2} mx={-1} style={{borderBottom: '4px solid #e5ebed'}}>
    {label && selectedOptions && showPreview && <>
            {selectedOptions && selectedOptions.map((p: any) => {
            const program = programs.find(program => p.value === program.nid)
            if (program && program.images && program.images.main2x3 && label) {
                return <Box width={1/4} p={1}>
                        <img style={{maxWidth: '100%', background:'#e5ebed' }} src={`${proxyUrl}?source=${program.images.main2x3}&text=${labelRow.label}`}/>
                        <img style={{maxWidth: '100%', background: '#e5ebed'}} src={`${proxyUrl}?source=${program.images.main16x9Annotated}&text=${labelRow.label}`}/>
                    </Box>
                }
                else {
                    return <Box width={1/6}>
                         <strong> hittade ingen bild för "{p.value}" i comet. </strong>
                    </Box>
                }
            })
            } 
            </>
    }
    </Flex>
    </>
}