import React from 'react'
import styled from 'styled-components'

const MoveMenuContainer = styled.div`
  border: 1px solid gainsboro;
  padding: 8px;
  margin-top: 16px;
  button {
    font-size: 14px;
    padding: 4px;
    margin-right: 8px;
  }
`

export default ({ card, onMove, targets, onClose }) => {
  return (
    <MoveMenuContainer>
      <h2> Flytta Kort </h2>
      <p> Välj destination </p>
      {targets &&
        targets.map(target => (
          <div
            key={target}
            style={{
              padding: '4px 8px',
              border: '1px solid black',
              marginLeft: 4,
              display: 'inline-block',
            }}
          >
            <h3> {target} </h3>
            <button
              onClick={() => {
                onMove({
                  id: card.sys.id,
                  targetField: target,
                  append: false,
                })
              }}
            >
              först
            </button>
            <button
              key={target}
              onClick={() => {
                onMove({
                  id: card.sys.id,
                  targetField: target,
                  append: true,
                })
              }}
            >
              sist
            </button>
          </div>
        ))}
      <div style={{ marginTop: 8 }}>
        <button onClick={onClose}> Avbryt </button>
      </div>
    </MoveMenuContainer>
  )
}
