export const CONTENTFUL_STATUS = {
  UNKNOWN: {
    color: 'red',
    displayName: 'UNKNOWN',
  },
  DRAFT: {
    color: 'orange',
    displayName: 'DRAFT',
  },
  UPDATED: {
    color: '#5b9fef',
    displayName: 'UPDATED',
  },
  PUBLISHED: {
    color: 'green',
    displayName: 'PUBLISHED',
  },
}

export function getPublishStatus(item) {
  if (item.sys.status) {
    return item.sys.status
  }
  if (!item.sys) {
    return CONTENTFUL_STATUS.UNKNOWN
  }
  if (!item.sys.publishedVersion) {
    return CONTENTFUL_STATUS.DRAFT
  }
  return item.sys.version - 1 > item.sys.publishedVersion ? CONTENTFUL_STATUS.UPDATED : CONTENTFUL_STATUS.PUBLISHED
}
