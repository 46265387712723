import _get from 'lodash.get'
import { schedulingStatus } from '../../utils/SchedulingStatus'

export const parsePreviewData = ({ previewMetaData, originalEntry }) => {
  const publishFrom = _get(previewMetaData, 'fields.publishDates.from')
  const publishTo = _get(previewMetaData, 'fields.publishDates.to')
  return {
    ...originalEntry,
    ...previewMetaData,
    publishFrom,
    publishTo,
    schedulingStatus: schedulingStatus(publishFrom, publishTo),
    panelContentType: _get(previewMetaData, 'sys.contentType.sys.id'),
    title: _get(previewMetaData, 'fields.title'),
    internalName: _get(previewMetaData, 'fields.internalName'),
    platforms: _get(previewMetaData, 'fields.platforms'),
  }
}

export const parseManagementData = ({ deliverMetaData, managementMetaData, originalEntry }) => {
  const publishFrom = _get(managementMetaData, 'fields.publishDates.en-US.from')
  const publishTo = _get(managementMetaData, 'fields.publishDates.en-US.to')
  return {
    ...originalEntry,
    ...deliverMetaData,
    ...managementMetaData,
    publishFrom,
    publishTo,
    schedulingStatus: schedulingStatus(publishFrom, publishTo),
    panelContentType: _get(managementMetaData, 'sys.contentType.sys.id'),
    title: _get(managementMetaData, 'fields.title.en-US'),
    internalName: _get(managementMetaData, 'fields.internalName.en-US'),
    platforms: _get(managementMetaData, 'fields.platforms.en-US'),
  }
}
