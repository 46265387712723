import styled from 'styled-components'

export const AddButton = styled.button`
  padding: 0;
  background: 0;
  border: 0;
  width: 24px;
  cursor: pointer;
  margin-left: 16px;
  display: inline-block;
  :focus {
    outline: none;
  }
  &:hover {
    svg {
      fill: darkgreen;
    }
  }
  > svg {
    fill: green;
  }
`

export const NewsListContainer = styled.div`
  max-height: 800;
  min-height: 800;
  overflow: 'scroll';
  position: relative;

  h2 {
    user-select: none;
  }
`
export const DeleteEverything = styled.div`
  position: absolute;
  top: 14px;
  cursor: pointer;
  right: 8px;
  width: 24px;
  height: 24px;
  &:hover {
    svg {
      fill: red;
    }
  }
`
