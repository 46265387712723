import styled from 'styled-components'
import React from 'react'
const text = styled.span`
  text-transform: uppercase;
  vertical-align: middle;
  font-weight: 900;
`
const Dot = styled.span`
  height: 16px;
  font-size: 10px;
  color: ${'red'};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`

const LiveMarker = styled.div`
  display: inline-flex;
  margin-right: 8px;
  position: ${props => (props.position ? props.position : 'static')};
  top: ${props => (props.top ? props.top : 'auto')};
  left: ${props => (props.left ? props.left : 'auto')};
  bottom: ${props => (props.bottom ? props.bottom : 'auto')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};
  right: ${props => (props.right ? props.right : 'auto')};
  min-height: 24px;
  line-height: 16px;
  color: ${props => (props.textColor ? props.textColor : 'red')};
  text-transform: uppercase;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '0')};
  z-index: 5;
  ${text} {
    font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  }
`

LiveMarker.text = text
LiveMarker.dot = props => <Dot {...props}>•</Dot>

export default LiveMarker
