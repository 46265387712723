import _get from 'lodash.get'
import { schedulingStatus } from '../../utils/SchedulingStatus'

export const parsePreviewData = ({ previewMetaData, originalEntry }) => {
  const publishFrom = _get(previewMetaData, 'fields.publishDates.from')
  const publishTo = _get(previewMetaData, 'fields.publishDates.to')
  return {
    ...originalEntry,
    ...previewMetaData,
    publishFrom,
    publishTo,
    schedulingStatus: schedulingStatus(publishFrom, publishTo),
    linkContentType: _get(previewMetaData, 'sys.contentType.sys.id'),
    title: _get(previewMetaData, 'fields.title'),
    isPitchCard: _get(previewMetaData, 'fields.isPitchCard'),
    internalName: _get(previewMetaData, 'fields.internalName'),
    main16x9: _get(previewMetaData, 'fields.main16x9.fields.file.url'),
    main16x7: _get(previewMetaData, 'fields.main16x7.fields.file.url'),
    main4x3: _get(previewMetaData, 'fields.main4x3.fields.file.url'),
    main1x1: _get(previewMetaData, 'fields.main1x1.fields.file.url'),
  }
}

export const parseManagementData = ({ deliverMetaData, managementMetaData, originalEntry }) => {
  const publishFrom = _get(managementMetaData, 'fields.publishDates.en-US.from')
  const publishTo = _get(managementMetaData, 'fields.publishDates.en-US.to')
  return {
    ...originalEntry,
    ...managementMetaData,
    ...deliverMetaData,
    publishFrom,
    publishTo,
    schedulingStatus: schedulingStatus(publishFrom, publishTo),
    linkContentType: _get(managementMetaData, 'sys.contentType.sys.id'),
    title: _get(deliverMetaData, 'fields.title'),
    isPitchCard: _get(deliverMetaData, 'fields.isPitchCard'),
    internalName: _get(deliverMetaData, 'fields.internalName'),
    main16x9: _get(deliverMetaData, 'fields.main16x9.fields.file.url'),
    main16x7: _get(deliverMetaData, 'fields.main16x7.fields.file.url'),
    main4x3: _get(deliverMetaData, 'fields.main4x3.fields.file.url'),
    main1x1: _get(deliverMetaData, 'fields.main1x1.fields.file.url'),
  }
}
