import {useState} from 'react'
import { isBefore } from 'date-fns'

export default (initialFrom?: Date, initialTo?: Date) => {
    const [from, setFrom] = useState<Date|undefined>(initialFrom)
    const [to, setTo] = useState<Date|undefined>(initialTo)
    
    const updateFrom = (date: Date) => {
        setFrom(date)
    }
    
    const updateTo = (date: Date) => {
        // allow
        if (date === null) {
          setTo(undefined)
        }
        if (from && !isBefore(date, from)) {
          setTo(date)
        }
    }
    return {
        from,updateFrom,
        to, updateTo,
    }
}