import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import useDateRange from './useDateRange'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

const DateInputWrapper = styled.div`
  input {
    min-width: 225px;
    min-height: 40px;
    font-size: 18px;
  }
`
interface DateRangeProps {
  onChange: (from: Date | undefined, to:Date | undefined) => void
  fromValue?: Date
  toValue?: Date
}

export default ({onChange, fromValue, toValue}: DateRangeProps) => {
    const {from, to, updateFrom, updateTo} = useDateRange(fromValue, toValue)
    useEffect(() => {
      console.log('chaning daterange')
        onChange(from, to)
    }, [from, to])
    return <div style={{ display: 'flex', maxWidth: 500, flexWrap:"wrap" }}>
    <DateInputWrapper>
      <DatePicker
        selected={from}
        selectsStart
        showTimeSelect
        timeIntervals={5}
        timeFormat="HH:mm"
        maxDate={to}
        startDate={from}
        endDate={to}
        isClearable
        onChange={updateFrom}
        dateFormat="yyyy-MM-dd HH:mm"
      />
    </DateInputWrapper>
    <DateInputWrapper style={{marginTop: 18}}>
      <DatePicker
        selected={to}
        selectsEnd
        showTimeSelect
        timeIntervals={5}
        timeFormat="HH:mm"
        startDate={from}
        minDate={from}
        isClearable
        onChange={updateTo}
        dateFormat="yyyy-MM-dd HH:mm"
      />
    </DateInputWrapper>
    </div>
}