import React, { Component } from 'react'
import { isBefore, parse, isValid } from 'date-fns'
import { schedulingStatus, STATUS_DATA } from '../../utils/SchedulingStatus'
import PublishStatusLabel from '../styled/PublishStatusLabel'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import styled from 'styled-components'

function parseDate(date) {
  if (!date) {
    return null
  }
  const parsedDate = parse(date)
  return isValid(parsedDate) ? parsedDate : null
}

const DateInputWrapper = styled.div`
  input {
    min-width: 225px;
    min-height: 44px;
    font-size: 18px;
  }
`

export default class TagList extends Component {
  state = {
    from: null,
    to: null,
  }
  handleChangeFrom = date => {
    this.setState(
      {
        from: date,
      },
      () => {
        this.save()
      }
    )
  }
  handleChangeTo = date => {
    // allow
    if (date === null) {
      this.setState(
        {
          to: null,
        },
        () => {
          this.save()
        }
      )
    }
    if (!isBefore(date, this.state.from)) {
      this.setState(
        {
          to: date,
        },
        () => {
          this.save()
        }
      )
    }
  }
  componentDidMount() {
    window.contentfulExtension &&
      window.contentfulExtension.init(extension => {
        const values = extension.field.getValue() || {}
        const from = parseDate(values.from)
        const to = parseDate(values.to)
        extension.window.updateHeight(450)
        this.setState({
          from,
          to,
          extension,
        })
      })
  }
  save = () => {
    if (this.state.extension) {
      const from = this.state.from ? this.state.from.toISOString() : null;
      const to = this.state.to ? this.state.to.toISOString() : null;
      this.state.extension.field
        .setValue({
          from: from,
          to: to,
        })
        .then(result => {})
    }
  }

  render() {
    const { from, to } = this.state

    const status = schedulingStatus(from, to)
    return (
      <div>
        <div style={{ display: 'flex', maxWidth: 500 }}>
          <DateInputWrapper>
            <DatePicker
              selected={from}
              selectsStart
              showTimeSelect
              timeIntervals={5}
              timeFormat="HH:mm"
              maxDate={to}
              startDate={from}
              endDate={to}
              isClearable
              onChange={this.handleChangeFrom}
              dateFormat="yyyy-MM-dd HH:mm"
            />
          </DateInputWrapper>
          <DateInputWrapper>
            <DatePicker
              selected={to}
              selectsEnd
              showTimeSelect
              timeIntervals={5}
              timeFormat="HH:mm"
              startDate={from}
              minDate={from}
              isClearable
              onChange={this.handleChangeTo}
              dateFormat="yyyy-MM-dd HH:mm"
            />
          </DateInputWrapper>
          <button onClick={this.save}> Spara </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', minHeight: 450 }}>
          <PublishStatusLabel status={status} /> {STATUS_DATA[status].text}
        </div>
      </div>
    )
  }
}
