import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import '@contentful/forma-36-react-components/dist/styles.css'
import VideoAssetFinder from './components/VideoAssetFinder'
import Taglist from './components/Taglist'
import VideoAssetList from './components/VideoAssetList'
import CardsEditor from './components/CardsEditor'
import PanelsEditor from './components/PanelsEditor'
import DateRangeEditor from './components/DateRangeEditor'
import PlatformSelector from './components/PlatformSelector'
import TrailerUploader from './components/TrailerUploader'
import TrailerUploaderV2 from './components/TrailerUploaderV2'
import ShowcaseTargetSelector from './components/ShowcaseTargetSelector'
import NewsList from './components/NewsList'
import Playlist from './components/Playlist'
import SuggestedEpisode from './components/SuggestedEpisode'
import BoolWithDefault from './components/BoolWithDefault'
import RadioWithDefault from './components/RadioWithDefault'
import SelectWithDefault from './components/SelectWithDefault'
import CometFieldSelector from './components/CometFieldSelector'
import LabelAssigner from './components/LabelAssigner'
import CDPSelector from './components/CDPSelector'
import 'whatwg-fetch'
import 'react-datepicker/dist/react-datepicker.css'
import VideoAssetFinderV2 from './components/VideoAssetFinderV2'
import TagListV2 from './components/TaglistV2'

/* disable usage of contentful-ui-extensions because of webpack issues with their bundled code
  that code is included through a script-tag in index.html for now.
*/

const routeComponentMap = {
  '/boolwithdefault': BoolWithDefault,
  '/cardseditor': CardsEditor,
  '/cdpselector': CDPSelector,
  '/cometfieldselector': CometFieldSelector,
  '/daterange': DateRangeEditor,
  '/labelassigner': LabelAssigner,
  '/newslist': NewsList,
  '/panelseditor': PanelsEditor,
  '/platformselector': PlatformSelector,
  '/playlist': Playlist,
  '/radiowithdefault': RadioWithDefault,
  '/selectwithdefault': SelectWithDefault,
  '/showcasetargetselector': ShowcaseTargetSelector,
  '/suggestedepisode': SuggestedEpisode,
  '/taglist': Taglist,
  '/taglistV2': TagListV2,
  '/traileruploader': TrailerUploader,
  '/traileruploaderv2': TrailerUploaderV2,
  '/videoassetfinder': VideoAssetFinder,
  '/videoassetfinderV2': VideoAssetFinderV2,
  '/videoAssetList': VideoAssetList,
}

const TableOfContent = () => {
  return (
    <div style={{ padding: '1em' }}>
      <h1>Extensions</h1>
      <ul style={{ paddingLeft: '1em' }}>
        {Object.entries(routeComponentMap).map(([path, component]) => (
          <li key={path} style={{ lineHeight: 1.5 }}>
            <a href={path}>{path}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

// import ExtensionContext from './ExtensionContext'
// import { init } from 'contentful-ui-extensions-sdk' // causes webpack issues.

const Routes = () => (
  <Router>
    <Switch>
      {Object.entries(routeComponentMap).map(([path, component]) => (
        <Route key={path} path={path} exact component={component} />
      ))}
      <Route path="/" component={TableOfContent} />
    </Switch>
  </Router>
)

export default ReactDOM.render(<Routes />, document.getElementById('root'))
// export default init(sdk => {
//   console.log('initting!', sdk)
//   ReactDOM.render(
//     <ExtensionContext.Provider value={{ sdk, loading: false }}>
//       <Routes sdk={sdk} />
//     </ExtensionContext.Provider>,
//     document.getElementById('root')
//   )
// })
