/* eslint-disable camelcase */
import { format, isYesterday, isToday, addMinutes } from 'date-fns'
import svLocale from 'date-fns/locale/sv'

export const formatSeconds = seconds => {
  const date = new Date(1970, 0, 1)
  date.setSeconds(seconds)
  return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
}

export const formatBroadcastDate = broadcastDate => {
  const broadcastDateAsDate = new Date(broadcastDate)
  const formattedBroadcastDate = addMinutes(broadcastDateAsDate, broadcastDateAsDate.getTimezoneOffset())
  const dateFormat = format(formattedBroadcastDate, 'D MMM YYYY', { locale: svLocale })
  const timeFormat = format(formattedBroadcastDate, 'HH:mm')
  return isToday(formattedBroadcastDate)
    ? `Idag ${timeFormat}`
    : isYesterday(formattedBroadcastDate)
      ? `Igår ${timeFormat}`
      : `${dateFormat} ${timeFormat}`
}
