import styled from 'styled-components'

export const ErrorMessage = styled.div`
  background-color: 'red';
  color: 'white';
`
export const PublishStatus = styled.span`
  color: ${props => (props.status === 'UPDATED' ? '#3e97d6' : '#33ca6c')};
`
export const ScheduledStatus = styled.span`
  color: ${props => (props.scheduled === 'SCHEDULED' ? '#8B0000' : '#696969')};
  text-transform: uppercase;
`

export const CardButton = styled.button`
  background-color: #5b9fef;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin: 4px;
  padding: 8px 16px;
  :focus {
    outline: none;
  }
`

export const Date = styled.div`
  width: 58px;
  font-weight: 600;
  display: inline-block;
`
export const PitchLabel = styled.div`
  display: inline-block;
  color: #fff;
  font-size: 11px;
  background-color: #ababab;
  padding: 2px 4px;
`

export const RefreshButton = styled.button`
  position: fixed;
  background: #fff;
  z-index: 3;
  left: 500px;
  top: 16px;
  cursor: pointer;
  min-height: 32px;
  min-width: 32px;
  :hover {
    svg {
      fill: #4caf50;
    }
  }
`
