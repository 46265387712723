import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { VideoListItem, ImageWrap, MetaData, MetaDataWrapper, AssetId, TimeStamp } from './style'
import LiveMarker from '../styled/LiveMarker'
import { formatSeconds, formatBroadcastDate } from '../../utils/timeFormatting'
import { imageProxyUrl } from '../../modules/image-proxy'
import { Clock } from 'styled-icons/fa-regular/Clock'
import { Play } from 'styled-icons/feather/Play'
import Loader from '../styled/Loader'
import AssetError from './AssetError'
const SPACE_ID = process.env.REACT_APP_SPACE_ID

const iconStyle = {
  width: 10,
  marginLeft: 5,
  marginRight: 3,
}
const videoAssetQuery = gql`
  query videoAssets($id: String!) {
    videoAsset(id: $id) {
      id
      image
      title
      description
      duration
      live
      broadcastDateTime
      publishedDateTime
    }
  }
`

export default ({ id, sysId, onRemoveItem, extension }) => (
  <Query query={videoAssetQuery} variables={{ id: `${id}` }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div>
            <Loader style={{ margin: '0 10px 0 5px' }}>Loading Video Info</Loader>
          </div>
        )
      }
      if (error || !data.videoAsset || !id) {
        return <AssetError sysId={sysId} onRemoveItem={onRemoveItem} />
      }
      const { duration, description, title, image, broadcastDateTime, live } = data.videoAsset
      return (
        <VideoListItem>
          <ImageWrap>
            <img alt={title} src={imageProxyUrl(image, 100)} style={{ maxWidth: 100 }} />
            <div>{formatSeconds(duration)}</div>
          </ImageWrap>
          <MetaData>
            <MetaDataWrapper>
              <h3>
                {title}
                {live && (
                  <LiveMarker style={{ marginLeft: 5 }}>
                    <LiveMarker.dot /> <LiveMarker.text>Live</LiveMarker.text>
                  </LiveMarker>
                )}
              </h3>
              <TimeStamp>
                <Clock style={iconStyle} />
                {formatBroadcastDate(broadcastDateTime)}
              </TimeStamp>
              <AssetId>
                <Play style={iconStyle} /> <span> {id} </span>
              </AssetId>
            </MetaDataWrapper>
            <div>{description && description.length > 180 ? description.substr(0, 177) + '...' : description}</div>
            <div style={{ marginTop: 8 }}>
              <a
                onClick={e => {
                  extension.navigator.openEntry(sysId, { slideIn: true })
                  e.preventDefault()
                }}
                href={`https://app.contentful.com/spaces/${SPACE_ID}/entries/${sysId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Gå till asset
              </a>
            </div>
          </MetaData>
          <button
            onClick={() => {
              onRemoveItem(sysId)
            }}
          >
            Ta bort
          </button>
        </VideoListItem>
      )
    }}
  </Query>
)
