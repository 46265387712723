import React from 'react'
import { Tablet } from 'styled-icons/material/Tablet'
import { Smartphone } from 'styled-icons/material/Smartphone'
import { LaptopChromebook as Web } from 'styled-icons/material/LaptopChromebook'
import { Tv as Bigscreen } from 'styled-icons/material/Tv'
import { SelectMultiple as All } from 'styled-icons/boxicons-regular/SelectMultiple'
import styled from 'styled-components'

const IconWrapper = styled.div`
  margin-top: 4px;
  display: inline-block;
  opacity: ${props => (props.active ? '1' : '0.3')};
  max-width: 32px;
  svg {
    width: 32px;
    height: 32px;
    display: block;
  }
  span {
    display: block;
    font-size: 10px;
    width: 100%;
    text-align: center;
  }
`

export default ({ mobile, tablet, web, bigscreen }) => {
  return (
    <>
      <IconWrapper title="Mobile" active={mobile}>
        <Smartphone />
        <span>Phone</span>
      </IconWrapper>

      <IconWrapper title="Tablet" active={tablet}>
        <Tablet />
        <span>Tablet</span>
      </IconWrapper>

      <IconWrapper title="Web" active={web}>
        <Web />
        <span>Web</span>
      </IconWrapper>
      <IconWrapper title="Big screen" active={bigscreen}>
        <Bigscreen />
        <span>Big</span>
      </IconWrapper>
      <IconWrapper title="All" active={mobile && tablet && web && bigscreen}>
        <All />
        <span>All</span>
      </IconWrapper>
    </>
  )
}
