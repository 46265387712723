const contentfulManagement = require('contentful-management')
const SPACE_ID = process.env.REACT_APP_SPACE_ID

export async function createEntry({ contentType, id, fields, shouldPublish = true, token, environment }) {
  const client = contentfulManagement.createClient({
    accessToken: token,
    environment,
  })
  return new Promise((resolve, reject) => {
    client
      .getSpace(SPACE_ID)
      .then(space =>
        space.getEnvironment(environment).then(env =>
          env
            .createEntryWithId(contentType, id, {
              fields: fields,
            })
            .then(entry => {
              if (shouldPublish) {
                entry.publish()
              }
              resolve(entry)
            })
        )
      )
      .catch(err => reject(err))
  })
}

export async function getEntryData({ ids, token, environment }) {
  const client = contentfulManagement.createClient({
    accessToken: token,
    environment,
  })
  const space = await client.getSpace(SPACE_ID)
  const env = await space.getEnvironment(environment)
  const entries = await env.getEntries({
    include: 10,
    'sys.id[in]': ids.join(','),
  })
  return entries.items
}
