import React from 'react'
import { Flex, Box } from 'rebass'
import { RemoveCircleOutline } from 'styled-icons/material/RemoveCircleOutline'
import { DragIndicator } from 'styled-icons/material/DragIndicator'
import { VIDEO_ASSET_QUERY } from '../queries'
import { Query } from 'react-apollo'
import { formatBroadcastDate } from '../../../utils/timeFormatting'
import { NewsPostContainer, NoLongerAvailableText, DragHandle, CTAButton, MetaData } from './style'
import { PlayCircleOutline } from 'styled-icons/material/PlayCircleOutline'
import { AccessTime } from 'styled-icons/material/AccessTime'
import { Cross } from 'styled-icons/icomoon/Cross'

export default ({ newsPost: playlistItem, onRemove, extension }) => {
  const { images, title, id, pitch } = playlistItem
  const shouldSkip = !id || id.length < 1
  return (
    <Query query={VIDEO_ASSET_QUERY} skip={shouldSkip} variables={{ id: `${id}` }}>
      {({ error, data, loading }) => {
        const stillPublished = !loading && data && data.videoAsset
        if (loading) {
          return (
            <>
              <NewsPostContainer
                width={1}
                flexWrap="wrap"
                style={{ border: !loading && !stillPublished ? '1px dashed red' : 'none', paddingLeft: 24 }}
              >
                <Box width={3 / 12} pr={2} backgroundColor="#e5ebed">
                  <div style={{ paddingBottom: '56.25%' }} />
                </Box>
                <Box width={8 / 12} p={16}>
                  Laddar <i>{title}</i> <b>({id}) </b>
                </Box>
                <Box width={1}>
                  <Box width={3 / 12}>
                    <div style={{ background: '#e5ebed', margin: '4px 0', height: 16, width: '70%' }} />
                    <div style={{ background: '#e5ebed', height: 16, width: '70%' }} />
                  </Box>
                </Box>
              </NewsPostContainer>
            </>
          )
        }
        return (
          <NewsPostContainer
            width={1}
            flexWrap="wrap"
            style={{ border: !loading && !stillPublished ? '1px dashed red' : 'none' }}
          >
            <>
              <Box width={1}>
                {!stillPublished && (
                  <NoLongerAvailableText>
                    Denna video verkar inte vara publicerad längre
                    <button
                      onClick={() => {
                        onRemove(id)
                      }}
                    >
                      Ta Bort
                    </button>
                  </NoLongerAvailableText>
                )}
              </Box>
              <DragHandle width={'24px'} alignItems="center" mr={2}>
                <DragIndicator style={{ width: 24 }} />
              </DragHandle>

              <Box width={3 / 12} pr={2}>
                {stillPublished && (
                  <>
                    <img src={images.main16x9} style={{ maxWidth: '100%' }} alt={'videons bild'} />
                    <MetaData width={1} pb={1}>
                      <span>
                        <a href={`https://www.tv4play.se/nyheterna/${id}`} target="_blank" rel="noopener noreferrer">
                          <PlayCircleOutline /> {id}
                        </a>
                        <span
                          style={{ color: data.videoAsset.live ? 'red' : 'inherit', marginLeft: 16, fontWeight: 700 }}
                        >
                          {data.videoAsset.live ? 'Live' : data.videoAsset.humanDuration}
                        </span>
                      </span>
                      <span>
                        <AccessTime /> {formatBroadcastDate(data.videoAsset.broadcastDateTime)}
                      </span>
                    </MetaData>
                  </>
                )}
                {!stillPublished && (
                  <div style={{ textAlign: 'center', paddingTop: 16 }}>
                    <Cross style={{ fill: 'rgba(0,0,0,0.5)', height: 44, width: 44 }} />
                  </div>
                )}
              </Box>
              <Box width={8 / 12}>
                <Flex width={1} justifyContent="space-between">
                  <p style={{ fontWeight: 600, maxWidth: '95%', display: 'inline-block' }}>{title}</p>
                  <CTAButton
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      onRemove(id)
                    }}
                  >
                    <RemoveCircleOutline />
                  </CTAButton>
                </Flex>
                <p>{pitch}</p>
              </Box>
            </>
          </NewsPostContainer>
        )
      }}
    </Query>
  )
}
