import React, { useState } from 'react'
import styled from 'styled-components'
import { STATUS_DATA } from '../../utils/SchedulingStatus'
import { CONTENTFUL_STATUS, getPublishStatus } from '../../modules/contentful-status'
import { Box } from 'rebass'
import { CARD_TYPES } from './CardTypes'
import { RemoveCircleOutline } from 'styled-icons/material/RemoveCircleOutline'
import { UnfoldMore } from 'styled-icons/material/UnfoldMore'
import { Edit } from 'styled-icons/fa-regular/Edit'
import { Duplicate } from 'styled-icons/boxicons-regular/Duplicate'
import { Move } from 'styled-icons/boxicons-regular/Move'
import CardFullDetails from './CardFullDetails'
import CardMinimalDetails from './CardMinimalDetails'
import MoveMenu from './MoveMenu'
const SPACE_ID = process.env.REACT_APP_SPACE_ID

const ICONS_SIZE = '24px'

const EditorCard = styled(Box)`
  padding-left: 4px;
  min-height: 68px;
  position: relative;
  border-left: ${props =>
    props.status && props.publishStatus !== CONTENTFUL_STATUS.DRAFT
      ? `3px solid ${STATUS_DATA[props.status].color}`
      : 'none'};
  a {
    color: #000;
  }
`

const RemoveButton = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: flex-end;
  top: 2px;
  cursor: pointer;
  min-height: ${ICONS_SIZE};
  min-width: ${ICONS_SIZE};
  :hover {
    opacity: 0.6;
  }
`

const MoreInfoButton = styled.div`
  position: absolute;
  right: 132px;
  top: 2px;
  cursor: pointer;
  min-height: ${ICONS_SIZE};
  min-width: ${ICONS_SIZE};
  :hover {
    opacity: 0.6;
  }
`

const MoveButton = styled.div`
  position: absolute;
  right: 96px;
  top: 2px;
  cursor: pointer;
  min-height: ${ICONS_SIZE};
  min-width: ${ICONS_SIZE};
  :hover {
    opacity: 0.6;
  }
`
const DuplicateButton = styled.div`
  position: absolute;
  right: 68px;
  top: 2px;
  cursor: pointer;
  min-height: ${ICONS_SIZE};
  min-width: ${ICONS_SIZE};
  :hover {
    opacity: 0.6;
  }
`
const EditLink = styled.a`
  position: absolute;
  top: 2px;
  right: 38px;
  display: inline-block;
  min-height: ${ICONS_SIZE};
  min-width: ${ICONS_SIZE};
  :hover {
    opacity: 0.6;
  }
`

const ContentfulStatusLabel = styled.div`
  position: absolute;
  right: 8px;
  top: 44px;
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: 4px;
  font-weight: 600;
`

const CardEditorItem = ({
  card,
  currentField,
  duplicateItem,
  entryId,
  environment,
  index,
  isMosaic,
  loading,
  onExpandCard,
  onMove,
  onNavigateToEntry,
  onRemoveItem,
}) => {
  const [moveMenuActive, setMoveMenuActive] = useState(false)
  const { schedulingStatus, linkContentType } = card

  const editLinkTarget = `https://app.contentful.com/spaces/${SPACE_ID}/environments/${environment}/entries/${
    card.sys.id
  }?previousEntries=${entryId}`

  let displayName = 'UNKNOWN CARD'

  if (linkContentType) {
    displayName = CARD_TYPES[card.linkContentType].displayName
  } else {
    return (
      <EditorCard>
        {!card ? 'Hämtar ... ' : 'Misslyckades med att hämta kort. Detta kan bero på att det blivit avpublicerat. '}
        <EditLink target="_parent" href={editLinkTarget}>
          <Edit />
        </EditLink>
        <button
          onClick={() => {
            onRemoveItem(card.sys.id)
          }}
        >
          Ta bort från listan
        </button>
      </EditorCard>
    )
  }

  const contentfulPublishStatus = getPublishStatus(card)

  return (
    <EditorCard publishStatus={contentfulPublishStatus} status={schedulingStatus}>
      {card.expanded ? (
        <CardFullDetails
          contentfulPublishStatus={contentfulPublishStatus}
          schedulingStatus={schedulingStatus}
          displayName={displayName}
          index={index}
          card={card}
        />
      ) : (
        <CardMinimalDetails displayName={displayName} card={card} />
      )}
      <MoreInfoButton onClick={() => onExpandCard(index)}>
        <UnfoldMore width={ICONS_SIZE} height={ICONS_SIZE} />
      </MoreInfoButton>
      <RemoveButton
        onClick={() => {
          onRemoveItem(card.sys.id)
        }}
      >
        <RemoveCircleOutline width={ICONS_SIZE} height={ICONS_SIZE} />
      </RemoveButton>

      <EditLink
        target="_parent"
        onClick={e => {
          onNavigateToEntry({ e, entryId: card.sys.id })
        }}
      >
        <Edit width={ICONS_SIZE} height={ICONS_SIZE} />
      </EditLink>
      <DuplicateButton
        onClick={() => {
          duplicateItem({
            contentType: card.sys.contentType.sys.id,
            id: card.sys.id,
          })
        }}
      >
        <Duplicate />
      </DuplicateButton>
      {isMosaic && (
        <MoveButton
          onClick={() => {
            setMoveMenuActive(!moveMenuActive)
          }}
        >
          <Move />
        </MoveButton>
      )}

      {isMosaic &&
        moveMenuActive && (
          <MoveMenu
            targets={['slot1', 'slot2', 'slot3', 'slot4', 'slot5', 'slot6'].filter(slot => slot !== currentField)}
            onMove={onMove}
            card={card}
            onClose={() => setMoveMenuActive(false)}
          />
        )}
      <ContentfulStatusLabel color={contentfulPublishStatus.color}>
        {contentfulPublishStatus.displayName}
      </ContentfulStatusLabel>
    </EditorCard>
  )
}
export default CardEditorItem
