import React, { useState, useEffect } from 'react'
import { Button } from '@contentful/forma-36-react-components'
import { Flex, Box } from 'rebass'
import { LabelRow } from './types'
import gql from 'graphql-tag'
import produce, { Draft } from 'immer'
import ProgramLabelRow from './ProgramLabelRow'
import useExtensionSDK from '../hooks/useExtensionSDK'
import { isEqual } from 'lodash'
import ProgramLabelEditor from './ProgramLabelEditor'
const uuid = require('uuid/v1')
const client = require('../../graphql/apolloClient').default

export default () => {
    const { sdk, loading } = useExtensionSDK()
    const [persistedValue, setPersistedValue] = useState()
    const [fetching, setFetching] = useState<any>(true)
    const [programs, setPrograms] = useState<any>([])
    const [labelRows, setLabelRows] = useState<LabelRow[]>([])
    useEffect(() => {
        if (sdk) {
            sdk.window.startAutoResizer()
            const persistedValue = sdk.field.getValue()
            console.log('values?', persistedValue)
            if (persistedValue) {
                setPersistedValue(persistedValue)
                setLabelRows(persistedValue)
            }
        }
    }, [sdk])

    useEffect(() => {
        if (sdk) {
            if (!isEqual(persistedValue, labelRows)) {
                sdk.field.setValue(labelRows)
                setPersistedValue(labelRows)
            }
        }
    }, [labelRows])

    const onLabelChanged = (labelRow: LabelRow) => {
        const nextState = produce((labelRows: Draft<LabelRow[]>) => {
            const ix = labelRows.findIndex((label: any) => label.id === labelRow.id)
            if (ix > -1) {
                labelRows[ix] = { ...labelRow }
            }
            return labelRows
        })
        setLabelRows(nextState)
    }
    // const onDuplicate = (labelRow: LabelRow) => {
    //     const nextState = produce((labelRows: Draft<LabelRow[]>) => {
    //         console.log('updating', labelRows)
    //         const ix = labelRows.findIndex(label => label.id === labelRow.id)
    //         if (ix > -1) {
    //             labelRows[ix] = {...labelRow}
    //         }
    //         return labelRows
    //     })
    // }

    const onRemove = (ix: number) => {
        const nextState = labelRows.slice()
        nextState.splice(ix, 1)
        console.log('nextState', nextState)
        setLabelRows(nextState)
    }

    useEffect(() => {
        client.query({
            query: gql`{
                programSearch(perPage: 500) {
                  totalHits
                  programs {
                    id
                    nid
                    images {
                        main2x3
                        main16x9
                        main16x9Annotated
                    }
                  }
                }
              }`
        }).then((response: any) => {
            setPrograms(response.data.programSearch.programs)
            setFetching(false)
        })
    })

    if (fetching) {
        return <div> fetching data ... </div>
    }

    return <Flex py={2} width={1} flexWrap="wrap" mx={2}>
        {labelRows.map((programConfig, ix) => {
            return <ProgramLabelEditor
                key={programConfig.id}
                onRemove={() => onRemove(ix)}
                // onDuplicate={(labelRow) => {
                //     console.log('hej', labelRow)
                // }}
                onChange={onLabelChanged}
                labelRow={programConfig}
                programs={programs} />
        })}
        <Box>
            <Button buttonType="positive" onClick={() => {
                setLabelRows(labelRows.concat([{ id: uuid(), programs: [] }]))
            }}> Add new label configuration </Button>
        </Box>
    </Flex>
}
