import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import TextareaAutosize from 'react-autosize-textarea'

export const TextField = styled(TextareaAutosize)`
  display: block;
  resize: none;
  width: 100%;
  font-size: 14px;
  border: 0;
  resize: none;
  overflow: hidden;
  cursor: stylus;
`

export const MetaData = styled(Box)`
  font-size: 12px;
  > span {
    display: block;
    width: 100%;
  }
  svg {
    height: 16px;
    width: 16px;
  }
`

export const NoLongerAvailableText = styled.div`
  background: red;
  text-align: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  button {
    color: black;
    background: #fff;
    border: 0;
    margin-left: 16px;
    padding: 4px 8px;
    border-radius: 2px;

    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`

export const UploaderOverlay = styled.div`
  position: relative;
  cursor: pointer;
  span {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    display: none;
    color: #fff;
    font-size: 12px;
    bottom: 8px;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  svg {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    max-width: 25%;
    fill: #fff;
  }

  &:hover {
    svg,
    span {
      display: block;
    }
  }

  input,
  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    opacity: 0;
    z-index: 3;
  }
`
export const DragHandle = styled(Flex)`
  transition: background-color 300ms ease-in-out;
  background: #f7f9fa;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e5ebed;
`
export const CTAButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  width: 16px;
  &:hover {
    svg {
      fill: red;
    }
  }
`

export const NewsPostContainer = styled(Flex)`
  box-sizing: border-box;
  :hover {
    outline: 1px solid #1683d0;
    ${DragHandle} {
      background: #e5ebed;
    }
  }
`
